import React from 'react';
import './Home.css';
import { Col, Row } from "react-bootstrap";

export default class Admin extends React.Component {
    // React components are simple functions that take in props and state, and render HTML
    render() {
        return (
            <div className="holder">
                <h2>Administration</h2>

                Welcome, administrator.
                <br></br><br></br>
                The following options are available:
                <Row>
                    <Col>
                        <div><strong>Students/Appointments</strong></div>
                        <Row style={{ margin: "10px" }}><a href="/roadTestDates" className="btn btn-primary normButton" role="button">Manage Road Test Dates</a></Row>
                        <Row style={{ margin: "10px" }}><a href="/appointments" className="btn btn-primary normButton" role="button">Manage Appointments</a></Row>
                        <Row style={{ margin: "10px" }}><a href="/attendeelist" className="btn btn-primary normButton" role="button">Print Road Test Schedule/Roster</a></Row>
                        <br></br>
                        <Row style={{ margin: "10px" }}><a href="/roadTestPackages" className="btn btn-primary normButton" role="button">Manage Packages Offered</a></Row>
                        <Row style={{ margin: "10px" }}><a href="/roadTestLocations" className="btn btn-primary normButton" role="button">Manage Lesson Locations</a></Row>

                        <br></br>
                        <Row style={{ margin: "10px" }}><a href="/modify" className="btn btn-primary normButton" role="button">Modify Student Data</a></Row>
                        <br></br>
                        <Row style={{ margin: "10px" }}><a href="/studentTable" className="btn btn-primary normButton" role="button">View All Student Data</a></Row>
                        <Row style={{ margin: "10px" }}><a href="/transactions" className="btn btn-primary normButton" role="button">View Yearly Transaction Data</a></Row>

                    </Col>
                    <Col>
                        <div><strong>Student Lesssons</strong></div>
                        <Row style={{ margin: "10px" }}> <a href="/manageRoadLessons" className="btn btn-primary normButton" role="button">Add/Manage Road Lessons</a></Row>
                        <Row style={{ margin: "10px" }}><a href="/viewAllRoadLessons" className="btn btn-primary normButton" role="button">View All Road Lessons</a></Row>
                        <Row style={{ margin: "10px" }}><a href="/roadLessonInstructors" className="btn btn-primary normButton" role="button">Manage Road Lesson Instructors</a></Row>
                    </Col>

                </Row>

                <br></br>

            </div >

        );
    }
}
