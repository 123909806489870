import { API, Auth } from "aws-amplify";
import moment from 'moment';
import React from 'react';
import { Accordion, Alert, Button, Card, Col, Form, Modal, Row, Table, InputGroup } from 'react-bootstrap';
import TimePicker from 'react-bootstrap-time-picker';
import DayPicker from 'react-day-picker';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { CSVLink } from 'react-csv';
import { Locations } from "../components/contstants/Locations";

import { timeFromInt, timeToInt } from 'time-number';
import './Appointment.css';


var headers = [
    { label: 'First Name', key: 'fName' },
    { label: 'Middle Name', key: 'mName' },
    { label: 'Last Name', key: 'lName' },
    { label: 'DLN', key: 'permitNo' },
    { label: 'Date Of Birth (MM/DD/YYYY)', key: 'dob' },
    { label: 'Student Email', key: 'emailAddress' },
    { label: 'Total Program Cost', key: 'totalProgram' },
    { label: 'Payment Type', key: 'paymentType' },
    { label: 'Initial Payment', key: 'initalPayment' }
];

export default class Registration extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            addModalErrors: {},

            activeKey: 'selectDays',
            selectedDay: "",
            fetchedStudents: false,
            APIDateStudentResults: [],
            filteredAPIDateStudentResults: [],
            selectedLocation: "",
            selectedWeekday: "",
            roadTestMembers: [],
            unslotedMembers: [],
            selectedDaysRange: "",

            addModalValidated: false,

            unsavedManuallyAddedStudents: [],

            selectedTimeSlot: { "label": "10 Minutes", "value": 10 },
            slotTime: 10,
            startTime: "43200",
            endTime: "64800",

            dateOptions: [],
            selectedDate: "",
            slots: [],
            selectedDays: [],
            selectedRealDays: [],

            dayOfExam: '',
            preSlottedMembers: [],

            fetchingStudents: false,
            showAPIResponse: false,
            refreshing: false,
            emailSendDateData: "",
            //For use in modals
            showModal: false,
            //modalView: "",
            modalView: "add",
            //For lesson modal
            showManageLessons: false,
            showAddLessons: false,
            selectedLessonStudent: {},
            selectedLessonValue: {},
            selectedLessonLocation: "",
            newLessonsToAdd: [],
            selectedLessonStudentIndex: 0,
            //For if add UserModal is used
            firstName: "",
            lastName: "",
            dateOfBirth: "",
            permitNumber: "",
            permitIssueDate: "",
            email: "",
            phoneNumber: "",
            roadTestPackageOption: "",
            price: "",
            avaliableOptions: [],
            drivingSchool: "",
            location: "",
            addCompletionCertificate: false,
            emailsToCopy: "",
            disabledButtons: [],

            selectedStudent: 0,
            newCopyMoveDate: {},
            newEditMoveDate: {},
            moveError: "",
            copyError: "",
            deleteError: "",

            rawRoadTestLessons: [],

            addLessonValidated: false,
            CCFirstName: "",
            CCLastName: "",
            CCExpiration: "",
            CCCode: "",
            CCAddress: "",
            CCCity: "",
            CCState: "",
            CCZip: "",
            CCCountry: "USA",
            CCNumber: "",
            CCLessonsWanted: 1,
            CCLessonsPrice: 0,
            CCErrors: "",
            skipExtraLessonsPayment: false,

            locations: Locations,
            unslotedGroupTimes: [],
            timesToStudentMap: new Map()
        };
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleFirstNextButton = this.handleFirstNextButton.bind(this);
        this.handleDayOfWeekButton = this.handleDayOfWeekButton.bind(this);
        this.handleModalChange = this.handleModalChange.bind(this);
        this.onRadioPackageChange = this.onRadioPackageChange.bind(this);

        this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
        this.handleEndtimeTimeChange = this.handleEndtimeTimeChange.bind(this);

        this.addNewStudents = this.addNewStudents.bind(this);
        this.handleMoveStudent = this.handleMoveStudent.bind(this);
        this.handleCopyStudent = this.handleCopyStudent.bind(this);
        this.handleDeleteStudent = this.handleDeleteStudent.bind(this);

        this.handleAddNewUser = this.handleAddNewUser.bind(this);
        this.handleAddNewLessons = this.handleAddNewLessons.bind(this);
        this.sortSlots = this.sortSlots.bind(this);
        this.handleSlotSave = this.handleSlotSave.bind(this);
        this.handleUnslottedTimeSave = this.handleUnslottedTimeSave.bind(this);

        this.checkEmailDate = this.checkEmailDate.bind(this);
        this.handleSetLocationAndGetData = this.handleSetLocationAndGetData.bind(this);
        this.processLocationSlots = this.processLocationSlots.bind(this);
        this.renderLessonState = this.renderLessonState.bind(this);
        this.renderUnslottedStudentTimes = this.renderUnslottedStudentTimes.bind(this);

        this.handleLessonDateChange = this.handleLessonDateChange.bind(this);
        this.deleteNewLesson = this.deleteNewLesson.bind(this);
        this.deleteOldLesson = this.deleteOldLesson.bind(this);

        this.addAdditionalLessons = this.addAdditionalLessons.bind(this);

        this.loadRoadTestLessonDates = this.loadRoadTestLessonDates.bind(this);
        this.handleNewMoveDateChoice = this.handleNewMoveDateChoice.bind(this);
        this.handleNewCopyDateChoice = this.handleNewCopyDateChoice.bind(this);
        this.sendInitalEmailBulk = this.sendInitalEmailBulk.bind(this);
        this.sendRoadTestPassedEmailsBulk = this.sendRoadTestPassedEmailsBulk.bind(this);
        this.sendRoadTestFailedEmailsBulk = this.sendRoadTestFailedEmailsBulk.bind(this);
        this.sendRoadTestSlotEmailsBulk = this.sendRoadTestSlotEmailsBulk.bind(this);
        this.sendRoadTestTimeEmailsBulk = this.sendRoadTestTimeEmailsBulk.bind(this);
        this.subtractUnslottedTime = this.subtractUnslottedTime.bind(this);
    }

    componentDidMount() {
        const script = document.createElement("script");
        //SANDBOX
        //script.src = "https://jstest.authorize.net/v1/Accept.js";
        //PROD
        script.src = "https://js.authorize.net/v1/Accept.js";
        script.async = true;
        document.body.appendChild(script);


        const that = this;
        //We need to grab stuff
        fetch('https://s3.amazonaws.com/publicdates/roadTestDates.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then(this.handleErrors)
            .then(function (response) {
                return response.json();
            }).then(function (data) {
                data = JSON.parse(data);
                that.setState({ rawRoadTestLessons: data });
            });


        fetch('https://s3.amazonaws.com/publicdates/roadTestPackages.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then(this.handleErrors)
            .then(function (response) {
                return response.json();
            }).then(function (data) {
                that.setState({ avaliableOptions: data });
            });


    }

    async handleDayClick(date, { selected }) {
        let dayOfWeek = date.getDay();
        let selectedDays = [];
        let selectedRealDays = [];
        if (dayOfWeek === 0) { //They picked a Sunday, but we really want the start of the weekend
            selectedDays.push(moment(date).subtract(1, 'd').toDate());
            selectedDays.push(moment(date).toDate());
        }
        else if (dayOfWeek === 6) { //They picked a Saturday, no changes needed
            selectedDays.push(moment(date).toDate());
            selectedDays.push(moment(date).add(1, 'd').toDate());
        }
        else {
            selectedRealDays.push(moment(date).toDate());
            selectedDays.push(moment(date).toDate());
        }
        selectedRealDays = selectedRealDays.concat(selectedDays);

        this.setState({ selectedRealDays, selectedDays, selectedLocation: "", selectedWeekday: "" }, () => this.processLocationSlots());
    }

    processLocationSlots = async () => {
        let selectedDaysRange = "";

        let selectedDay = this.state.selectedDays[0];
        var location = this.state.selectedLocation;
        let APIDateStudentResults = await this.handleGetSlotsbyDay(selectedDay);
        let filteredData = [];
        let slots = [];
        var preSlottedFolks = [];
        if (location.length === 0) {
            var selectedLocationSet = new Set(APIDateStudentResults.filter(x => x.location).map(x => x.location));
            var selectedDateLocations = [...selectedLocationSet];
            if (selectedDateLocations.length === 1) {
                location = selectedDateLocations[0];
            }
        }
        if (location.length > 0) {
            filteredData = APIDateStudentResults.filter(x => x.location === location);
        }

        if (selectedDay.getDay() === 6) {
            selectedDaysRange += moment(selectedDay).format('LL');
            if (filteredData.length > 0) {
                var selectedWeekenedDates = new Set(filteredData.filter(x => x.weekendDate).map(x => x.weekendDate));

                selectedWeekenedDates = [...selectedWeekenedDates];
                if (selectedWeekenedDates.length > 0) {//Only one date, let's assume it's that one and move on.
                    var selectedWeekday = selectedWeekenedDates[0];
                    var weekendArray = ["Saturday", "Sunday"];
                    if (weekendArray.includes(selectedWeekday)) {
                        preSlottedFolks = filteredData.filter(x => x.slot);
                        if (preSlottedFolks.length > 0) {
                            slots = preSlottedFolks.map(function (x) { return { slotName: x.slot, person: x.fName + " " + x.lName, value: x }; });
                            this.setState({ dayOfExam: this.state.selectedDays[weekendArray.indexOf(selectedWeekday)], selectedWeekday });
                        }
                    } else {
                        weekendArray = ["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
                        preSlottedFolks = filteredData.filter(x => x.slot);
                        if (preSlottedFolks.length > 0) {
                            slots = preSlottedFolks.map(function (x) { return { slotName: x.slot, person: x.fName + " " + x.lName, value: x }; });
                            this.setState({ dayOfExam: moment(selectedDay).add(weekendArray.indexOf(selectedWeekday), "days"), selectedWeekday });
                        }
                    }
                }
            }
            else {
                this.setState({ dayOfExam: selectedDay, selectedWeekday: "" });
            }
        } else {
            selectedDaysRange = moment(selectedDay).format('ddd, LL');
            preSlottedFolks = filteredData.filter(x => x.slot);
            if (filteredData.length > 0) {
                var selectedWeekenedDates = new Set(filteredData.filter(x => x.weekendDate).map(x => x.weekendDate));

                selectedWeekenedDates = [...selectedWeekenedDates];
                if (selectedWeekenedDates.length > 0) {//Only one date, let's assume it's that one and move on.
                    var selectedWeekday = selectedWeekenedDates[0];

                    var weekendArray = ["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
                    preSlottedFolks = filteredData.filter(x => x.slot);
                    console.log(selectedWeekday);

                    if (preSlottedFolks.length > 0) {
                        slots = preSlottedFolks.map(function (x) { return { slotName: x.slot, person: x.fName + " " + x.lName, value: x }; });
                        this.setState({ dayOfExam: moment(selectedDay).subtract(2, "day").add(weekendArray.indexOf(selectedWeekday), "days"), selectedWeekday });
                    }
                } else {
                    //No date was selected but there can only be one date.
                    console.log(moment(selectedDay).format('dddd'));
                    this.setState({ dayOfExam: selectedDay, selectedWeekday: moment(selectedDay).format('dddd'), selectedDaysRange, dayOfExam: selectedDay });
                    this.setState({ selectedTimeSlot: { "label": "60 Minutes", "value": 60 }, slotTime: 60 });
                    var unslotTimes = filteredData.filter(x => x.location === location).filter(x => !x.slot).filter(x => x.hasOwnProperty("unslotTime")).map(x => ({ "slotName": x.unslotTime }));
                    unslotTimes = [...new Set(unslotTimes)];
                    this.setState({ unslotedGroupTimes: this.sortSlots(unslotTimes, 'slotName') });
                }
            }

            if (preSlottedFolks.length > 0) {
                slots = preSlottedFolks.map(function (x) { return { slotName: x.slot, person: x.fName + " " + x.lName, value: x }; });
            }
        }
        //We need to figure out if there is an already selected Weekday from all of the students that were fetched.

        //The selected date is the first entry of the selectedDays
        slots = this.sortSlots(slots, 'slotName');
        this.setState({
            selectedDay,
            APIDateStudentResults,
            selectedDaysRange,
            unsavedManuallyAddedStudents: [],
            selectedLocation: location,
            filteredAPIDateStudentResults: filteredData,
            unslotedMembers: filteredData.filter(x => x.location === location).filter(x => !x.slot),
            slots

        });
    };

    handleGetSlotsbyDay = async (day) => {
        let dateNeeded = moment(day).format("YYYY-MM-DD");
        this.setState({ loadingDay: true });
        const that = this;

        let session = await Auth.currentSession();
        let options = {
            body: { "date": dateNeeded },
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': session.idToken.jwtToken,
            }
        };
        try {
            let data = await API.post("APIGateway", "/v2getStudentsByFilter", options);
            that.setState({ loadingDay: false, fetchedStudents: true });
            return data;

        }
        catch (e) {
            console.log(e);
            return ([]);
        }
    };

    handleDayOfWeekButton(event) {
        var APIDateStudentResults = this.state.APIDateStudentResults;
        var weekArray = ["sat", "sun", "mon", "tue", "wed", "thu", "fri"];
        var weekendArray = ["sat", "sun"];
        var preSlottedFolks = this.state.APIDateStudentResults.filter(x => x.slot);
        if (preSlottedFolks.length > 0) {
            var slots = preSlottedFolks.map(function (x) { return { slotName: x.slot, person: x.fName + " " + x.lName, value: x }; });
            this.setState({ slots: this.sortSlots(slots, 'slotName') });
        }
        let dayOfWeek = this.state.selectedDay.getDay();
        console.log(event.target.id);
        var dayOfExam = "";
        var selectedWeekday = "";

        if (dayOfWeek === 6) {
            let daysToAdd = weekArray.indexOf(event.target.id);
            dayOfExam = moment(this.state.selectedDays[0]).add(daysToAdd, "days");
            selectedWeekday = moment(dayOfExam).format('dddd');

            this.setState({ dayOfExam, activeKey: "slotStudents", selectedWeekday });
        }
        else {
            dayOfExam = this.state.selectedRealDays[weekArray.indexOf(event.target.id)];
            selectedWeekday = moment(dayOfExam).format('dddd');
            this.setState({ dayOfExam, activeKey: "slotStudents", selectedWeekday });
        }
        this.setState({ unslotedMembers: APIDateStudentResults.filter(x => !x.slot), dataDirty: true });
        return;
    }

    handleFirstNextButton() {
        if (this.state.selectedWeekday.length === 0) {
            var APIDateStudentResults = this.state.APIDateStudentResults;
            var weekArray = ["sat", "sun", "mon", "tue", "wed", "thu", "fri"];
            var weekendArray = ["sat", "sun"];
            var preSlottedFolks = this.state.APIDateStudentResults.filter(x => x.slot);
            if (preSlottedFolks.length > 0) {
                var slots = preSlottedFolks.map(function (x) { return { slotName: x.slot, person: x.fName + " " + x.lName, value: x }; });
                this.setState({ slots: this.sortSlots(slots, 'slotName') });
            }
            if (typeof this.state.selectedDay.getDay == "undefined") {
                return;
            }
            let dayOfWeek = this.state.selectedDay.getDay();
            var dayOfExam = "";
            var selectedWeekday = "";

            if (dayOfWeek === 6) {
                let daysToAdd = weekArray.indexOf("sat");
                dayOfExam = moment(this.state.selectedDays[0]).add(daysToAdd, "days");
                selectedWeekday = moment(dayOfExam).format('dddd');

                this.setState({ dayOfExam, activeKey: "slotStudents", selectedWeekday });
            }
            else {
                dayOfExam = this.state.selectedRealDays[weekArray.indexOf("sat")];
                selectedWeekday = moment(dayOfExam).format('dddd');
                this.setState({ dayOfExam, activeKey: "slotStudents", selectedWeekday });
            }
            this.setState({ unslotedMembers: APIDateStudentResults.filter(x => !x.slot), dataDirty: true });
        }
        this.setState({ activeKey: "slotStudents" });
    }


    handleFilterDownload = async event => {
        this.setState({ loading: true });

        let data = [];

        for (let index = 0; index < this.state.APIDateStudentResults.length; index++) {
            var student = this.state.APIDateStudentResults[index];
            console.log(student);
            student.totalProgram = student.fullPricing.map(x => x.price).reduce((a, b) => a + b, 0);
            student.paymentType = "Credit Card";
            student.initalPayment = student.totalProgram;

            data.push(student);
        }




        this.setState({ filteredData: data, renderDownload: true }, () => {
            this.filterCSVLink.link.click();
            this.setState({ loading: false });
        });
    };

    //Modal Functions
    handleModalInputChange = e => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        });
    };

    handleModalChange(state, view) {
        this.setState({ showModal: state, modalView: view });
    }

    handleLessonModalChange(state, index) {
        if (state) {
            let selectedLessonStudent = this.state.filteredAPIDateStudentResults[index];
            let selectedLessonStudentIndex = index;
            this.setState({ selectedLessonStudent, newLessonsToAdd: [], selectedLessonValue: {}, selectedLessonStudentIndex });

        }
        this.setState({ showManageLessons: state });
    }

    onRadioPackageChange(e) {
        this.setState({
            roadTestPackageOption: e.currentTarget.id
        });
    }

    handleAddNewUser(event) {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        this.setState({ addModalValidated: true });
        if (form.checkValidity() === false) {
            return;
        }

        var fullPricing = [];

        var packageChoice = this.state.avaliableOptions.filter(x => x.package === this.state.roadTestPackageOption)[0];
        fullPricing.push(
            {
                "name": packageChoice.package,
                "price": parseInt(packageChoice.price)
            }
        );

        if (this.state.addCompletionCertificate) {
            fullPricing.push(
                {
                    "name": "Completion Certificate",
                    "price": 20
                }
            );
        }


        var person = {
            date: moment(this.state.selectedDays[0]).format('YYYY-MM-DD'),
            timestamp: new Date(Date.now()).toISOString(),
            transactionID: "Manual Input",
            paymentStatus: "Manual Input",

            fName: this.state.firstName,
            lName: this.state.lastName,

            dob: moment(this.state.dateOfBirth).format('YYYY-MM-DD'),
            permitNo: this.state.permitNumber,
            permitDate: moment(this.state.permitIssueDate).format('YYYY-MM-DD'),
            phone: this.state.phoneNumber,

            weekendDate: this.state.selectedWeekday,
            selectedPackage: this.state.roadTestPackageOption,
            fullPricing: fullPricing,

            emailAddress: this.state.email,
            emailInitalState: "Not Sent",
            emailRoadTestSlotState: "Not Sent",
            emailRoadTestSlotConfirmed: "Email Not Sent",
            emailRoadTestPassedState: "Not Sent",
            drivingSchool: this.state.drivingSchool || "Unknown",

            location: this.state.selectedLocation,
            certPaymentStatus: this.state.addCompletionCertificate,

            bookedLessons: []
        };


        Object.keys(person).forEach((key) => (person[key] == null || person[key].length === 0) && delete person[key]);


        var item = { "mode": "addNewStudentNoSlot", "student": person };
        var unsavedManuallyAddedStudents = this.state.unsavedManuallyAddedStudents;
        unsavedManuallyAddedStudents.push(item);
        this.setState({ unsavedManuallyAddedStudents });

        this.setState({
            firstName: "",
            lastName: "",
            dateOfBirth: "",
            permitNumber: "",
            permitIssueDate: "",
            email: "",
            phoneNumber: "",
            roadTestPackageOption: "",
            drivingSchool: "",
            dataDirty: true,
            certPaymentStatus: false,
            addModalValidated: false
        });
    }


    subtractUnslottedTime = (selectedUnslottedTimeslotName) => {
        var unslotedGroupTimes = this.state.unslotedGroupTimes;
        console.log(unslotedGroupTimes);
        unslotedGroupTimes = unslotedGroupTimes.filter(x => x.slotName !== selectedUnslottedTimeslotName);

        this.setState({ unslotedGroupTimes });
    };

    handleAddNewLessons(event) {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        this.setState({ addLessonValidated: true });
        if (form.checkValidity() === false) {
            return;
        }
        this.setState({ loading: true });



        if (this.state.skipExtraLessonsPayment) {
            this.addAdditionalLessons();
            return;
        }

        //PROD
        var authData = {
            clientKey: "62CpRk5LpV9a3ZVd3r9ngCk3Uw3Le4ZDAJ8fL8E8n4xMQ653P2hWtGV89HbC22eU",
            apiLoginID: "2w2YM4z9p"
        };

        var cardData = {
            cardNumber: this.state.CCNumber.replace(/\s/g, ''),
            month: this.state.CCExpiration.substring(0, 2),
            year: this.state.CCExpiration.substring(this.state.CCExpiration.length - 2, this.state.CCExpiration.length),
            cardCode: this.state.CCCode,
            zip: this.state.CCZip,
            fullName: this.state.CCFirstName + " " + this.state.CCLastName,
        };

        var secureData = {
            authData: authData,
            cardData: cardData
        };
        try {
            window.Accept.dispatchData(secureData, this.responseHandler);
        } catch (error) {
            console.log(error);
        }
    }

    responseHandler = async (response) => {
        try {
            if (response.messages.resultCode === "Error") {
                var i = 0;
                while (i < response.messages.message.length) {
                    console.log(
                        response.messages.message[i].code + ": " +
                        response.messages.message[i].text
                    );
                    i = i + 1;
                }
            }
            else {
                //The Authorize NET request was successful!
                var lessonName = this.state.CCLessonsWanted;
                lessonName += this.state.CCLessonsWanted == 1 ? " Extra Lesson" : " Extra Lessons";
                var student = this.state.selectedLessonStudent;
                var newLesson = { "name": lessonName, "price": parseInt(this.state.CCLessonsPrice) };
                if (student.hasOwnProperty("fullPricing")) {
                    var fullPricing = student.fullPricing;
                    fullPricing.push(newLesson);
                    student.fullPricing = fullPricing;
                } else {
                    student.fullPricing = [newLesson];
                }
                this.setState({ CCErrors: "", loading: true });
                const self = this;
                let nonce = response.opaqueData.dataValue;
                let body = {
                    nonce: nonce,
                    newLesson: newLesson,
                    CCFirstName: this.state.CCFirstName,
                    CCLastName: this.state.CCLastName,
                    CCAddress: this.state.CCAddress,
                    CCCity: this.state.CCCity,
                    CCState: this.state.CCState,
                    CCZip: this.state.CCZip,
                    CCCountry: this.state.CCCountry,
                    student: student,
                    lessonName: lessonName,
                    lessonPrice: this.state.CCLessonsPrice,
                };

                let session = await Auth.currentSession();
                let options = {
                    body: body,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': session.idToken.jwtToken
                    }
                };
                API.post("APIGateway", "/addNewRoadLessonsPayment", options)
                    .then(function (data) {
                        if (data.type === 'success') {
                            self.setState({
                                showAddLessons: false,
                                CCFirstName: "",
                                CCLastName: "",
                                CCExpiration: "",
                                CCCode: "",
                                CCAddress: "",
                                CCCity: "",
                                CCState: "",
                                CCZip: "",
                                CCCountry: "",
                                CCNumber: "",
                                CCLessonsWanted: 0,
                                CCLessonsPrice: 0,
                                CCErrors: "",
                                loading: false,
                                addLessonValidated: false,
                                skipExtraLessonsPayment: false
                            });
                        }
                        else {
                            if (["Transaction Failed with error code 2 : This transaction has been declined.", "Transaction Failed with error code 65 : This transaction has been declined."].includes(data.message)) {
                                console.log("Errors: " + "There was an issue processing your card. Please double check your credit card entries.");

                            }
                            else {
                                console.log("Errors: " + "There was an issue processing your card. Please double check your credit card entries." + data.message);

                            }
                        }
                    }
                    ).catch(function (error) {
                        console.log(error);
                        if (error.response) {
                            self.setState({ CCErrors: error.response.data.message });
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error', error.message);
                        }
                        self.setState({ loading: false });
                    });
            }
        } catch (error) {
            console.log(error);
        }
    };


    async addNewStudents() {
        let newStudents = this.state.unsavedManuallyAddedStudents;
        this.setState({ loading: true });
        const that = this;

        let session = await Auth.currentSession();
        let options = {
            body: newStudents,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': session.idToken.jwtToken
            }
        };
        API.post("APIGateway", "/modifystudentdata", options)
            .then(function (data) {
                if (data.hasOwnProperty('type') && data.type === "success") {
                    that.setState({ unsavedManuallyAddedStudents: [], loading: false });
                    that.refreshStatus(that.state.activeKey);
                }
                else {
                    //that.setState({ APIDateStudentResults: [] })
                    console.log("No students found");
                }
            }).catch(function (error) {
                console.log(error);
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                that.setState({ loading: false });
            });
    }

    async handleMoveStudent() {
        this.setState({ loading: true, moveError: "" });

        let student = this.state.APIDateStudentResults[this.state.selectedStudent];

        if (this.state.newEditMoveDate.label === student.date) {
            this.setState({ moveError: "The student is already at this date.", loading: false });
            return;
        }
        if (!moment(this.state.newEditMoveDate.label, 'YYYY-MM-DD', true).isValid()) {
            this.setState({ moveError: "Invalid Date Entry.", loading: false });
            return;
        }
        //date is being changed, so we need to update the email data to clear out any potential emails sent.
        if (student.emailInitalState !== "Not Sent") {
            student.emailInitalState = "Not Sent";
        }
        if (student.emailRoadTestPassedState !== "Not Sent") {
            student.emailRoadTestPassedState = "Not Sent";
        }
        if (student.emailRoadTestSlotConfirmed !== "Email Not Sent") {
            student.emailRoadTestSlotConfirmed = "Email Not Sent";
        }
        if (student.emailRoadTestSlotState !== "Not Sent") {
            student.emailRoadTestSlotState = "Not Sent";
        }
        if (student.hasOwnProperty("validateToken")) {
            delete student.validateToken;
        }
        if (student.hasOwnProperty("slot")) {
            delete student.slot;
        }
        if (student.hasOwnProperty("weekendDate")) {
            delete student.weekendDate;
        }

        var item = { "mode": "moveStudent", "student": student, "newDate": this.state.newEditMoveDate.label, "oldDate": student.date };
        delete student.date;
        const that = this;

        let session = await Auth.currentSession();
        let options = {
            body: [item]
            ,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': session.idToken.jwtToken
            }
        };
        API.post("APIGateway", "/modifystudentdata", options)
            .then(function (data) {
                if (data.hasOwnProperty('type') && data.type === "success") {

                    //Call function that updates students
                    that.refreshStatus(that.state.activeKey);
                    that.setState({ loading: false, showModal: false, modalView: "", newEditMoveDate: {} });
                    //that.setState({ dataDirty: false, showMoveModal: false, selectedStudent: 0, selectedNewDate: that.state.possibleMoveDates[0], loading: false })
                }
                else {
                    console.log("No students found");
                }
            }).catch(function (error) {
                console.log(error);
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });

    }
    async handleCopyStudent() {
        this.setState({ loading: true, copyError: "" });

        let student = this.state.APIDateStudentResults[this.state.selectedStudent];
        console.log(student);

        if (this.state.newCopyMoveDate.label === student.date) {
            this.setState({ copyError: "The student is already at this date.", loading: false });
            return;
        }
        if (!moment(this.state.newCopyMoveDate.label, 'YYYY-MM-DD', true).isValid()) {
            this.setState({ copyError: "Invalid Date Entry.", loading: false });
            return;
        }
        else {
            let dow = moment(this.state.newCopyMoveDate.label, 'YYYY-MM-DD', true).day();
            if (!(dow === 6 || dow === 1)) {
                this.setState({ copyError: "Date was not a Saturday, or Monday.", loading: false });
                return;
            }
        }

        var item = { "mode": "copyStudent", "student": student, "newDate": this.state.newCopyMoveDate.label, "oldDate": student.date };
        delete student.date;
        const that = this;

        let session = await Auth.currentSession();
        let options = {
            body: [item]
            ,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': session.idToken.jwtToken
            }
        };
        API.post("APIGateway", "/modifystudentdata", options)
            .then(function (data) {
                if (data.hasOwnProperty('type') && data.type === "success") {

                    //Call function that updates students
                    that.refreshStatus(that.state.activeKey);
                    that.setState({ loading: false, showModal: false, modalView: "", newCopyMoveDate: {} });
                }
                else {
                    console.log("No students found");
                }
            }).catch(function (error) {
                console.log(error);
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });

    }
    async handleDeleteStudent() {
        this.setState({ loading: true, moveError: "" });

        let student = this.state.APIDateStudentResults[this.state.selectedStudent];

        var item = { "mode": "deleteStudent", "student": student };
        const that = this;

        let session = await Auth.currentSession();
        let options = {
            body: [item]
            ,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': session.idToken.jwtToken
            }
        };
        API.post("APIGateway", "/modifystudentdata", options)
            .then(function (data) {
                if (data.hasOwnProperty('type') && data.type === "success") {
                    that.refreshStatus(that.state.activeKey);
                    that.setState({ loading: false, showModal: false, modalView: "" });
                }
                else {
                    console.log("No students found");
                }
            }).catch(function (error) {
                console.log(error);
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });

    }

    handleSlotDurationChange(data) {
        this.setState({ slotTime: parseInt(data.value), selectedTimeSlot: data });
    }


    handleNewMoveDateChoice(data) {
        this.setState({ newEditMoveDate: data });
    }

    handleNewCopyDateChoice(data) {
        this.setState({ newCopyMoveDate: data });
    }

    uuidv4 = () => {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    };

    copyStudentEmailsToClipboard = () => {
        var textField = document.createElement('textarea');
        textField.innerText = this.state.slots.map(x => x.value.emailAddress).join(",");
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
    };


    copyParentEmailsToClipboard = () => {
        var textField = document.createElement('textarea');
        textField.innerText = this.state.slots.filter(x => x.value.hasOwnProperty("parentEmailAddress")).map(x => x.value.parentEmailAddress).join(",");
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
    };

    copyAllPhoneNumbersToClipboard = () => {
        var textField = document.createElement('textarea');
        textField.innerText = this.state.slots.filter(x => x.value.hasOwnProperty("phone")).map(x => x.value.phone).join(",");
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
    };

    sortSlots(array, key) {
        if (array.length === 0) {
            return [];
        }
        return array.sort(function (a, b) {
            var x = timeToInt(a[key]); var y = timeToInt(b[key]);
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

    handleStudentPick = (selectedOption, index) => {
        var localSlots = this.state.slots;
        var personValue = localSlots[index].value;
        localSlots[index].person = selectedOption.label;
        localSlots[index].value = selectedOption.value;
        var unslotedMembers = this.state.unslotedMembers.filter(x => x.timestamp !== selectedOption.value.timestamp);
        if (Object.keys(personValue).length > 0 && personValue.constructor === Object) {
            unslotedMembers.push(personValue);
        }
        this.setState({ slots: localSlots, unslotedMembers: unslotedMembers });
    };


    handleUnslottedStudentPick = (selectedOptions, time, index) => {
        console.log(selectedOptions);
        var localUnslotedMembers = this.state.unslotedMembers;
        var finalizedSlots = [];

        if (selectedOptions == null || selectedOptions.length == 0) {
            console.log(time);
            var selectedStudentsWithTimestampSet = localUnslotedMembers.filter(x => x.hasOwnProperty("unslotTime") && x.unslotTime == time).map(x => x.timestamp);
            console.log(selectedStudentsWithTimestampSet);
            for (let i = 0; i < localUnslotedMembers.length; i++) {
                var student = localUnslotedMembers[i];
                if (selectedStudentsWithTimestampSet.includes(student.timestamp)) {
                    delete student.unslotTime;
                }
                finalizedSlots.push(student);
            }

        } else {
            var selectedStudentsWithoutTimestampSet = selectedOptions.filter(x => !x.hasOwnProperty("unslotTime")).map(x => x.value.timestamp);
            console.log(selectedStudentsWithoutTimestampSet);
            for (let i = 0; i < localUnslotedMembers.length; i++) {
                var student = localUnslotedMembers[i];
                if (selectedStudentsWithoutTimestampSet.includes(student.timestamp)) {
                    student.unslotTime = time;
                }
                finalizedSlots.push(student);
            }
        }


        this.setState({ unslotedMembers: finalizedSlots });
    };


    handleErrors = (response) => {
        if (!response.ok) {
            console.log(response.statusText);
        }
        return response;
    };

    async sendEmail(params) {
        const that = this;
        that.setState({ loading: true });
        let session = await Auth.currentSession();
        let options = {
            body: params,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': session.idToken.jwtToken
            }
        };
        API.post("APIGateway", "/sendemail", options)
            .then(function (data) {
                that.refreshStatus(that.state.activeKey);
            }).catch(function (error) {
                console.log(error);
                if (error.response) {
                    if (error.response.status === 400) {
                        //this.setState({ EmailError: "There was an error sending some emails.", refreshStatus: "" })
                        //that.setState({ APIDateStudentResults: [], disabledButtons: that.state.disabledButtons.filter(x => x !== selectedIndex) })
                    }
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    }

    async sendInitalEmailBulk(emailRecipient) { //"aka Thanks for signing up"
        let emailRecipients = emailRecipient;
        if (emailRecipient.length === 0) {
            emailRecipients = this.state.filteredAPIDateStudentResults.filter(x => (Object.keys(x).length > 0 && x.constructor === Object)).filter(x => x.emailInitalState === "Not Sent");
        }
        var dynamoDBDetails = [];
        let defaultTemplate = JSON.stringify(
            {
                "firstName": "tempFirst",
                "lastName": "tempLast",
                "package": "tempPackage",
                "weekendStart": "tempStartDate",
                "extraText": "",
                "address": "",
                "extraLocationText": "Road Test Location"
            }
        );
        var params = {
            Source: 'Parkway Driving School<info@parkwaydrivingschool.com>',
            Template: 'confirmOrder2',
            DefaultTemplateData: defaultTemplate,
            ReplyToAddresses: [
                'info@parkwaydrivingschool.com',
            ],
            Destinations: []
        };

        let lengthOfSelectedDays = this.state.selectedRealDays.length;
        let weekType = "Weekend";
        if (lengthOfSelectedDays === 5) {
            weekType = "Weekday";
        }
        for (let index = 0; index < emailRecipients.length; index++) {
            const formData = emailRecipients[index];
            if (formData.location.includes("RMV Site")) {
                weekType = "Weekday";
            }

            var lessonText = "";
            var extraLocationText = "Road Test Location:";

            if (formData.hasOwnProperty("scheduledLessons") && formData.scheduledLessons.length > 0) {
                extraLocationText = "Lesson & Road Test Location:";
                formData.scheduledLessons.map(lessonData => {
                    lessonText += moment(lessonData.date).format('MM-DD-YY') + " at " + lessonData.slotName + " in " + lessonData.location + "<br>";
                });
            }
            var weekendStartText = weekType + " of " + moment(this.state.selectedRealDays[0]).format("dddd, MMMM Do YYYY");
            if (lengthOfSelectedDays === 7) {
                weekendStartText = " Week of " + moment(this.state.selectedRealDays[0]).format('M/D') + " - " + moment(this.state.selectedRealDays[0]).add(6, "day").format('M/D') + " (Sat-Fri, Exact date tbd)";
            }

            var ReplacementTemplateData = {
                "firstName": formData.fName,
                "lastName": formData.lName,
                "package": formData.selectedPackage,
                "weekendStart": weekendStartText,
                "extraText": lessonText,
                "extraLocationText": extraLocationText
            };
            console.log(formData);

            switch (formData.location) {
                case "Walpole": {
                    ReplacementTemplateData["address"] = "1004 East St, Walpole, MA 02081";
                    ReplacementTemplateData["googleMapUrl"] = "https://maps.google.com/?q=1004+East+St,+Walpole";
                    break;
                } case "West Roxbury": {
                    ReplacementTemplateData["address"] = "48 Railroad Street, West Roxbury, MA 02132";
                    ReplacementTemplateData["googleMapUrl"] = "https://maps.google.com/?q=48+Railroad+Street,+West+Roxbury";
                    ReplacementTemplateData["extraLocationInstructions"] = "<div style=\"text-align: left;\"><span style=\"color:#555555\">WHERE TO GO</span><ul><li style=\"text-align: left;\"><span style=\"color:#555555\">Our road test location will be at the Highland Train Station, located at 48 Railroad Street in West Roxbury. The station is a few blocks from our school and is less congested and an easier starting point for our students. I have attached a map so you can find the location. It is next to Roche Bros, behind the post office and between Corey and Hastings Streets.</span></li><li style=\"text-align: left;\"><span style=\"color:#555555\"><strong>Please do not park in the train station parking lot.</strong>There is only one entrance and exit, and we would like to keep those areas clear for students taking their tests. Please park in the perpendicular parking spots at the end of Hastings Street or the public parking lot on Corey Street across from the station. I have marked these parking areas on the attached map. Our office will be open on the day of your exam, so please call for directions if you cannot find us.</span></li></ul><div style=\"text-align: left;\"><span style=\"color:#555555\"><img data-file-id=\"5644493\" height=\"326\" src=\"https://publicdates.s3.amazonaws.com/RoadTestLocationMap.jpg\" style=\"border: 0px ; width: 525px; height: 326px; margin: 0px;\" width=\"525\">.</span></div>";
                    ReplacementTemplateData["extraLocationInstructionsText"] = " WHERE TO GO\r\n  -   Our road test location will be at the Highland Train Station, located at 48 Railroad Street in West Roxbury. The station is a few blocks from our school and is less congested and an easier starting point for our students. I have attached a map so you can find the location. It is next to Roche Bros, behind the post office and between Corey and Hastings Streets. Please do not park in the train station parking lot. There is only one entrance and exit, and we would like to keep those areas clear for students taking their tests. Please park in the perpendicular parking spots at the end of Hastings Street or the public parking lot on Corey Street across from the station. I have marked these parking areas on the attached map. Our office will be open on the day of your exam, so please call for directions if you cannot find us, or have difficulty viewing the map. \r\n \r\n ";
                    break;
                } case "Brockton RMV Site": {
                    ReplacementTemplateData["address"] = "702 Belmont St, Brockton, MA 02301";
                    ReplacementTemplateData["googleMapUrl"] = "https://maps.google.com/?q=Asiaf+Skating+Arena+Brockton";
                    break;
                } case "Franklin RMV Site": {
                    ReplacementTemplateData["address"] = "910 Panther Way, Franklin, MA 02038";
                    ReplacementTemplateData["googleMapUrl"] = "https://maps.google.com/?q=Pirelli+Skating+Rink+Franklin";
                    break;
                }
                case "South Yarmouth RMV Site": {
                    ReplacementTemplateData["address"] = "South Yarmouth RMV - 1084 Route 28, South Yarmouth, MA 02664";
                    ReplacementTemplateData["googleMapUrl"] = "https://maps.google.com/?q=Massachusetts+Registry+of+Motor+Vehicles+South+Yarmouth+RMV";
                    ReplacementTemplateData["extraLocationInstructions"] = "<div style=\"text-align: left;\"><span style=\"color:#555555\">WHERE TO GO</span><ul><li style=\"text-align: left;\"><span style=\"color:#555555\">Our road test location will be at the South Yarmouth RMV. Our office will not be open on the day of your test.  Please text 617-564-3803 with any questions. This is a text line only.</span></li></ul><div style=\"text-align: left;\"><span style=\"color:#555555\">ADDITIONAL INFO/span><ul><li style=\"text-align: left;\"><span style=\"color:#555555\">I have attached an application. You must have your application and permit with you the day of the test. You will be testing in a Kia Soul. If you have any questions please reach out to roadtests@parkwaydrivingschool.com or text (617) 564-3803.</span></div>";
                    ReplacementTemplateData["extraLocationInstructionsText"] = " WHERE TO GO\r\n  -   Our road test location will be at the South Yarmouth RMV. Our office will not be open on the day of your test.  Please text 617-564-3803 with any questions. This is a text line only.\r\n \r\n ";
                    break;
                }
                case "Braintree RMV Site": {
                    ReplacementTemplateData["address"] = "Braintree RMV - 10 Plain St., Braintree, MA 02184";
                    ReplacementTemplateData["googleMapUrl"] = "https://maps.google.com/?q=Massachusetts+Registry+of+Motor+Vehicles+Braintree+RMV";
                    ReplacementTemplateData["extraLocationInstructions"] = "<div style=\"text-align: left;\"><span style=\"color:#555555\">WHERE TO GO</span><ul><li style=\"text-align: left;\"><span style=\"color:#555555\">Our road test location will be at the Braintree RMV. Our office will not be open on the day of your test.  Please text 617-564-3803 with any questions. This is a text line only.</span></li></ul><div style=\"text-align: left;\"><span style=\"color:#555555\">ADDITIONAL INFO/span><ul><li style=\"text-align: left;\"><span style=\"color:#555555\">I have attached an application. You must have your application and permit with you the day of the test. You will be testing in a Kia Soul. If you have any questions please reach out to roadtests@parkwaydrivingschool.com or text (617) 564-3803.</span></div>";
                    ReplacementTemplateData["extraLocationInstructionsText"] = " WHERE TO GO\r\n  -   Our road test location will be at the Braintree RMV. Our office will not be open on the day of your test.  Please text 617-564-3803 with any questions. This is a text line only.\r\n \r\n ";
                    break;
                } default:
                    ReplacementTemplateData["address"] = "Address TBA";
                    ReplacementTemplateData["googleMapUrl"] = "https://maps.google.com";
            }

            ReplacementTemplateData = JSON.stringify(ReplacementTemplateData);

            let dbDetails = {
                "date": formData.date,
                "timestamp": formData.timestamp
            };
            dynamoDBDetails.push(dbDetails);
            var data = {
                "Destination": {
                    "ToAddresses": [
                        formData.emailAddress
                    ]
                },
                "ReplacementTemplateData": ReplacementTemplateData
            };

            if (formData.parentEmailAddress && formData.parentEmailAddress.length > 0) {
                data["Destination"]["CcAddresses"] = [formData.parentEmailAddress];
            }
            params.Destinations.push(data);

        }
        let finalData = {
            params: params,
            dynamoDBDetails: dynamoDBDetails
        };
        if (finalData.dynamoDBDetails.length > 0) {
            this.sendEmail(finalData);
        } else {
            console.log("No emails to send");
        }
    }

    async sendRoadTestSlotEmailsBulk(input) { //"aka This is when your lesson is"
        let emailRecipients = [];
        if (input.length === 1) {
            emailRecipients = input;
        } else {
            emailRecipients = this.state.slots.filter(x => (Object.keys(x.value).length > 0 && x.value.constructor === Object)).filter(x => x.value.emailRoadTestSlotState === "Not Sent").map(x => x.value);
        }

        var dynamoDBDetails = [];

        let defaultTemplate = JSON.stringify(
            {
                "dateTime": "PLACE_DATE",
                "linkLocation": "https://bostonroadtests.com/confirmation",
                "address": "",
                "googleMapUrl": ""
            }
        );
        //Make sure Template casing is updated in lambda!
        var params = {
            Source: 'Parkway Driving School<info@parkwaydrivingschool.com>',
            Template: 'appointmentTemplate3',
            DefaultTemplateData: defaultTemplate,
            ReplyToAddresses: [
                'info@parkwaydrivingschool.com',
            ],
            Destinations: []
        };

        var finalTime = "";
        for (let index = 0; index < emailRecipients.length; index++) {
            var formData = emailRecipients[index];
            let token = this.uuidv4();
            finalTime = moment(this.state.dayOfExam).format("dddd, MMMM Do YYYY") + " at " + formData.slot;

            var ReplacementTemplateData = {
                "dateTime": finalTime,
                "linkLocation": "https://bostonroadtests.com/confirmation?dateStart=" + formData.date + "&token=" + token,
                "extraLocationInstructions": "",
                "extraLocationInstructionsText": ""
            };
            if (formData.location === "Walpole") {
                ReplacementTemplateData["address"] = "1004 East St, Walpole, MA 02081";
                ReplacementTemplateData["googleMapUrl"] = "https://maps.google.com/?q=1004+East+St,+Walpole";

            } else {
                ReplacementTemplateData["address"] = "48 Railroad Street, West Roxbury, MA 02132";
                ReplacementTemplateData["googleMapUrl"] = "https://maps.google.com/?q=48+Railroad+Street,+West+Roxbury";
                ReplacementTemplateData["extraLocationInstructions"] = "<div style=\"text-align: left;\"><span style=\"color:#555555\">WHERE TO GO</span><ul><li style=\"text-align: left;\"><span style=\"color:#555555\">Our road test location will be at the Highland Train Station, located at 48 Railroad Street in West Roxbury. The station is a few blocks from our school and is less congested and an easier starting point for our students. I have attached a map so you can find the location. It is next to Roche Bros, behind the post office and between Corey and Hastings Streets.</span></li><li style=\"text-align: left;\"><span style=\"color:#555555\"><strong>Please do not park in the train station parking lot.</strong>There is only one entrance and exit, and we would like to keep those areas clear for students taking their tests. Please park in the perpendicular parking spots at the end of Hastings Street or the public parking lot on Corey Street across from the station. I have marked these parking areas on the attached map. Our office will be open on the day of your exam, so please call for directions if you cannot find us.</span></li></ul><div style=\"text-align: left;\"><span style=\"color:#555555\"><img data-file-id=\"5644493\" height=\"326\" src=\"https://publicdates.s3.amazonaws.com/RoadTestLocationMap.jpg\" style=\"border: 0px ; width: 525px; height: 326px; margin: 0px;\" width=\"525\">.</span></div>";
                ReplacementTemplateData["extraLocationInstructionsText"] = " WHERE TO GO\r\n  -   Our road test location will be at the Highland Train Station, located at 48 Railroad Street in West Roxbury. The station is a few blocks from our school and is less congested and an easier starting point for our students. I have attached a map so you can find the location. It is next to Roche Bros, behind the post office and between Corey and Hastings Streets. Please do not park in the train station parking lot. There is only one entrance and exit, and we would like to keep those areas clear for students taking their tests. Please park in the perpendicular parking spots at the end of Hastings Street or the public parking lot on Corey Street across from the station. I have marked these parking areas on the attached map. Our office will be open on the day of your exam, so please call for directions if you cannot find us, or have difficulty viewing the map. \r\n \r\n ";
            }
            let dbDetails = {
                "date": formData.date,
                "timestamp": formData.timestamp,
                "token": token
            };
            dynamoDBDetails.push(dbDetails);
            var data = {
                "Destination": {
                    "ToAddresses": [
                        formData.emailAddress
                    ]
                },
                "ReplacementTemplateData": JSON.stringify(ReplacementTemplateData)
            };
            if (formData.parentEmailAddress && formData.parentEmailAddress.length > 0) {
                data["Destination"]["CcAddresses"] = [formData.parentEmailAddress];
            }
            params.Destinations.push(data);
        }
        let finalData = {
            params: params,
            dynamoDBDetails: dynamoDBDetails
        };
        if (finalData.dynamoDBDetails.length > 0) {
            this.sendEmail(finalData);
        } else {
            console.log("No emails to send");
        }
    }

    async sendRoadTestTimeEmailsBulk(input) { //"aka This is when your lesson is"
        let emailRecipients = [];
        if (input.length === 1) {
            emailRecipients = input;
        } else {
            emailRecipients = this.state.unslotedMembers.map(x => x.emailRoadTestSlotState === "Not Sent");
        }

        var dynamoDBDetails = [];

        let defaultTemplate = JSON.stringify(
            {
                "dateTime": "PLACE_DATE",
                "linkLocation": "https://bostonroadtests.com/confirmation",
                "address": "",
                "googleMapUrl": ""
            }
        );
        //Make sure Template casing is updated in lambda!
        var params = {
            Source: 'Parkway Driving School<info@parkwaydrivingschool.com>',
            Template: 'appointmentTemplate3',
            DefaultTemplateData: defaultTemplate,
            ReplyToAddresses: [
                'info@parkwaydrivingschool.com',
            ],
            Destinations: []
        };

        var finalTime = "";
        for (let index = 0; index < emailRecipients.length; index++) {
            var formData = emailRecipients[index];
            let token = this.uuidv4();
            finalTime = moment(this.state.dayOfExam).format("dddd, MMMM Do YYYY") + " at " + formData.unslotTime;
            console.log(formData);
            var ReplacementTemplateData = {
                "dateTime": finalTime,
                "linkLocation": "https://bostonroadtests.com/confirmation?dateStart=" + formData.date + "&token=" + token,
                "extraLocationInstructions": "",
                "extraLocationInstructionsText": ""
            };

            switch (formData.location) {
                case "Walpole": {
                    ReplacementTemplateData["address"] = "1004 East St, Walpole, MA 02081";
                    ReplacementTemplateData["googleMapUrl"] = "https://maps.google.com/?q=1004+East+St,+Walpole";
                    break;
                } case "West Roxbury": {
                    ReplacementTemplateData["address"] = "48 Railroad Street, West Roxbury, MA 02132";
                    ReplacementTemplateData["googleMapUrl"] = "https://maps.google.com/?q=48+Railroad+Street,+West+Roxbury";
                    ReplacementTemplateData["extraLocationInstructions"] = "<div style=\"text-align: left;\"><span style=\"color:#555555\">WHERE TO GO</span><ul><li style=\"text-align: left;\"><span style=\"color:#555555\">Our road test location will be at the Highland Train Station, located at 48 Railroad Street in West Roxbury. The station is a few blocks from our school and is less congested and an easier starting point for our students. I have attached a map so you can find the location. It is next to Roche Bros, behind the post office and between Corey and Hastings Streets.</span></li><li style=\"text-align: left;\"><span style=\"color:#555555\"><strong>Please do not park in the train station parking lot.</strong>There is only one entrance and exit, and we would like to keep those areas clear for students taking their tests. Please park in the perpendicular parking spots at the end of Hastings Street or the public parking lot on Corey Street across from the station. I have marked these parking areas on the attached map. Our office will be not open on the day of your test. Please text 617-564-3803 with any questions. This is a text line only.</span></li></ul><div style=\"text-align: left;\"><span style=\"color:#555555\"><img data-file-id=\"5644493\" height=\"326\" src=\"https://publicdates.s3.amazonaws.com/RoadTestLocationMap.jpg\" style=\"border: 0px ; width: 525px; height: 326px; margin: 0px;\" width=\"525\">.</span></div>";
                    ReplacementTemplateData["extraLocationInstructionsText"] = " WHERE TO GO\r\n  -   Our road test location will be at the Highland Train Station, located at 48 Railroad Street in West Roxbury. The station is a few blocks from our school and is less congested and an easier starting point for our students. I have attached a map so you can find the location. It is next to Roche Bros, behind the post office and between Corey and Hastings Streets. Please do not park in the train station parking lot. There is only one entrance and exit, and we would like to keep those areas clear for students taking their tests. Please park in the perpendicular parking spots at the end of Hastings Street or the public parking lot on Corey Street across from the station. I have marked these parking areas on the attached map. Our office will be not be open on the day of your test. Please text 617-564-3803 with any questions. This is a text line only. \r\n \r\n ";
                    break;
                } case "Brockton RMV Site": {
                    ReplacementTemplateData["address"] = "DCR Asiaf Skating Rink - 702 Belmont St, Brockton, MA 02301";
                    ReplacementTemplateData["googleMapUrl"] = "https://maps.google.com/?q=Asiaf+Skating+Arena+Brockton";
                    ReplacementTemplateData["extraLocationInstructions"] = "<div style=\"text-align: left;\"><span style=\"color:#555555\">WHERE TO GO</span><ul><li style=\"text-align: left;\"><span style=\"color:#555555\">Our road test location will be at the RMV Road Testing Site at the Asiaf Skating Arena at 702 Belmont Street in Brockton. This is not the Brockton RMV. The testing site is behind Brockton High School. Turn into the school parking lot and follow the road to behind the school. Our office will not be open on the day of your test.  Please text 617-564-3803 with any questions. This is a text line only.</span></li></ul><div style=\"text-align: left;\"><span style=\"color:#555555\"><img data-file-id=\"5644493\" height=\"326\" src=\"https://publicdates.s3.amazonaws.com/BrocktonRoadTestLocationMap.png\" style=\"border: 0px ; width: 559px; height: 496px; margin: 0px;\" width=\"559\">.</span></div><div style=\"text-align: left;\"><span style=\"color:#555555\">ADDITIONAL INFO/span><ul><li style=\"text-align: left;\"><span style=\"color:#555555\">I have attached an application. You must have your application and permit with you the day of the test. You will be testing in a Kia Soul. Please review this <a href=\"https://drive.google.com/file/d/1tfkf1vOTPerbGKD9t0wt_g-naE6gtPox/view\" target=\"_blank\"><span style=\"color:#FF0000\"> document on hand signals and what to do on a hill</span></a> as these will be questions asked during the test. Also, this RMV does the parallel parking in between cones. If you have any questions please reach out to roadtests@parkwaydrivingschool.com or text (617) 564-3803.</span></div>";
                    ReplacementTemplateData["extraLocationInstructionsText"] = " WHERE TO GO\r\n  -   Our road test location will be at the RMV Road Testing Site at the Asiaf Skating Arena at 702 Belmont Street in Brockton. This is not the Brockton RMV. The testing site is behind Brockton High School. Turn into the school parking lot and follow the road to behind the school. Our office will not be open on the day of your test.  Please text 617-564-3803 with any questions. This is a text line only.\r\n \r\n ";

                    break;
                } case "Franklin RMV Site": {
                    ReplacementTemplateData["address"] = "Pirelli Skating Rink - 910 Panther Way, Franklin, MA 02038";
                    ReplacementTemplateData["googleMapUrl"] = "https://maps.google.com/?q=Pirelli+Skating+Rink+Franklin";
                    break;
                }
                case "South Yarmouth RMV Site": {
                    ReplacementTemplateData["address"] = "South Yarmouth RMV - 1084 Route 28, South Yarmouth, MA 02664";
                    ReplacementTemplateData["googleMapUrl"] = "https://maps.google.com/?q=Massachusetts+Registry+of+Motor+Vehicles+South+Yarmouth+RMV";
                    ReplacementTemplateData["extraLocationInstructions"] = "<div style=\"text-align: left;\"><span style=\"color:#555555\">WHERE TO GO</span><ul><li style=\"text-align: left;\"><span style=\"color:#555555\">Our road test location will be at the South Yarmouth RMV. Our office will not be open on the day of your test.  Please text 617-564-3803 with any questions. This is a text line only.</span></li></ul><div style=\"text-align: left;\"><span style=\"color:#555555\">ADDITIONAL INFO/span><ul><li style=\"text-align: left;\"><span style=\"color:#555555\">I have attached an application. You must have your application and permit with you the day of the test. You will be testing in a Kia Soul. If you have any questions please reach out to roadtests@parkwaydrivingschool.com or text (617) 564-3803.</span></div>";
                    ReplacementTemplateData["extraLocationInstructionsText"] = " WHERE TO GO\r\n  -   Our road test location will be at the South Yarmouth RMV. Our office will not be open on the day of your test.  Please text 617-564-3803 with any questions. This is a text line only.\r\n \r\n ";
                    break;
                }
                case "Braintree RMV Site": {
                    ReplacementTemplateData["address"] = "Braintree RMV - 10 Plain St., Braintree, MA 02184";
                    ReplacementTemplateData["googleMapUrl"] = "https://maps.google.com/?q=Massachusetts+Registry+of+Motor+Vehicles+Braintree+RMV";
                    ReplacementTemplateData["extraLocationInstructions"] = "<div style=\"text-align: left;\"><span style=\"color:#555555\">WHERE TO GO</span><ul><li style=\"text-align: left;\"><span style=\"color:#555555\">Our road test location will be at the Braintree RMV. Our office will not be open on the day of your test.  Please text 617-564-3803 with any questions. This is a text line only.</span></li></ul><div style=\"text-align: left;\"><span style=\"color:#555555\">ADDITIONAL INFO/span><ul><li style=\"text-align: left;\"><span style=\"color:#555555\">I have attached an application. You must have your application and permit with you the day of the test. You will be testing in a Kia Soul. If you have any questions please reach out to roadtests@parkwaydrivingschool.com or text (617) 564-3803.</span></div>";
                    ReplacementTemplateData["extraLocationInstructionsText"] = " WHERE TO GO\r\n  -   Our road test location will be at the Braintree RMV. Our office will not be open on the day of your test.  Please text 617-564-3803 with any questions. This is a text line only.\r\n \r\n ";
                    break;
                }
                default:
                    ReplacementTemplateData["address"] = "Address TBA";
                    ReplacementTemplateData["googleMapUrl"] = "https://maps.google.com";
            }
            let dbDetails = {
                "date": formData.date,
                "timestamp": formData.timestamp,
                "token": token
            };
            dynamoDBDetails.push(dbDetails);
            var data = {
                "Destination": {
                    "ToAddresses": [
                        formData.emailAddress
                    ]
                },
                "ReplacementTemplateData": JSON.stringify(ReplacementTemplateData)
            };
            if (formData.parentEmailAddress && formData.parentEmailAddress.length > 0) {
                data["Destination"]["CcAddresses"] = [formData.parentEmailAddress];
            }
            params.Destinations.push(data);
        }
        let finalData = {
            params: params,
            dynamoDBDetails: dynamoDBDetails
        };
        if (finalData.dynamoDBDetails.length > 0) {
            console.log(finalData);

            this.sendEmail(finalData);
        } else {
            console.log("No emails to send");
        }
    }

    async sendRoadTestPassedEmailsBulk(slotMode) {
        this.setState({ EmailError: "" });
        var dynamoDBDetails = [];

        var params = {
            Source: 'Parkway Driving School<info@parkwaydrivingschool.com>',
            Template: 'roadTestPassed',
            DefaultTemplateData: '{"firstName": "templateFirstNameholder"}',
            ReplyToAddresses: [
                'info@parkwaydrivingschool.com',
            ],
            Destinations: []
        };
        let emailRecipients = [];
        if (slotMode) {
            emailRecipients = this.state.slots.filter(x => (Object.keys(x.value).length > 0 && x.value.constructor === Object)).filter(x => x.value.roadTestPassed === true && (x.value.roadTestLicenseEmailSent === false || !x.value.hasOwnProperty("roadTestLicenseEmailSent"))).map(x => x.value);;
        } else {
            emailRecipients = this.state.unslotedMembers.filter(x => x.roadTestPassed === true && (x.roadTestLicenseEmailSent === false || !x.hasOwnProperty("roadTestLicenseEmailSent")));
        }
        for (let index = 0; index < emailRecipients.length; index++) {
            const formData = emailRecipients[index];
            var ReplacementTemplateData = JSON.stringify({
                "firstName": formData.fName
            });
            let dbDetails = {
                "date": formData.date,
                "timestamp": formData.timestamp
            };
            dynamoDBDetails.push(dbDetails);
            var data = {
                "Destination": {
                    "ToAddresses": [
                        formData.emailAddress
                    ]
                },
                "ReplacementTemplateData": ReplacementTemplateData
            };
            if (formData.parentEmailAddress && formData.parentEmailAddress.length > 0) {
                data["Destination"]["CcAddresses"] = [formData.parentEmailAddress];
            }
            params.Destinations.push(data);
        }


        let finalData = {
            params: params,
            dynamoDBDetails: dynamoDBDetails
        };
        this.sendEmail(finalData);
    }

    async sendRoadTestFailedEmailsBulk(slotMode) {
        this.setState({ EmailError: "" });

        var dynamoDBDetails = [];

        var params = {
            Source: 'Parkway Driving School<info@parkwaydrivingschool.com>',
            Template: 'roadTestFailed',
            DefaultTemplateData: '{"firstName": "templateFirstNameholder"}',
            ReplyToAddresses: [
                'info@parkwaydrivingschool.com',
            ],
            Destinations: []
        };
        let emailRecipients = [];
        if (slotMode) {
            emailRecipients = this.state.slots.filter(x => (Object.keys(x.value).length > 0 && x.value.constructor === Object)).filter(x => (x.value.roadTestPassed === false || !x.value.hasOwnProperty("roadTestPassed")) && (x.value.roadTestLicenseEmailSent === false || !x.value.hasOwnProperty("roadTestLicenseEmailSent"))).map(x => x.value);
        } else {
            emailRecipients = this.state.unslotedMembers.filter(x => (x.roadTestPassed === false || !x.hasOwnProperty("roadTestPassed")) && (x.roadTestLicenseEmailSent === false || !x.hasOwnProperty("roadTestLicenseEmailSent")));
        }

        for (let index = 0; index < emailRecipients.length; index++) {
            const formData = emailRecipients[index];
            var ReplacementTemplateData = JSON.stringify({
                "firstName": formData.fName
            });
            let dbDetails = {
                "date": formData.date,
                "timestamp": formData.timestamp
            };
            dynamoDBDetails.push(dbDetails);
            var data = {
                "Destination": {
                    "ToAddresses": [
                        formData.emailAddress
                    ]
                },
                "ReplacementTemplateData": ReplacementTemplateData
            };
            if (formData.parentEmail && formData.parentEmail.length > 0) {
                data["Destination"]["CcAddresses"] = [formData.parentEmail];
            }
            params.Destinations.push(data);
        }

        let finalData = {
            params: params,
            dynamoDBDetails: dynamoDBDetails
        };
        this.sendEmail(finalData);
    }

    checkEmailDate() {
        this.setState({ EmailError: "" });
        let examDate = this.state.dayOfExam;

        var finalTime = "";
        finalTime = moment(examDate).format("dddd, MMMM Do YYYY");
        this.setState({ emailSendDateData: finalTime });
    }

    handleChangeRoadTestStatus = async (index, mode, quantity, useSlots) => {
        var students = [];
        if (useSlots) {
            var shownStudents = this.state.slots.filter(x => (Object.keys(x.value).length > 0 && x.value.constructor === Object));
            if (quantity === "all") {
                shownStudents.forEach(function (student) {
                    let studentData = {
                        "date": student.value.date,
                        "timestamp": student.value.timestamp
                    };
                    var item = { "mode": mode, "student": studentData };
                    students.push(item);
                });
            }
            else {
                let studentData = {
                    "date": shownStudents[index].value.date,
                    "timestamp": shownStudents[index].value.timestamp
                };
                var item = { "mode": mode, "student": studentData };
                students.push(item);
            }
        } else {
            var shownStudents = this.state.unslotedMembers;
            if (quantity === "all") {
                shownStudents.forEach(function (student) {
                    let studentData = {
                        "date": student.date,
                        "timestamp": student.timestamp
                    };
                    var item = { "mode": mode, "student": studentData };
                    students.push(item);
                });
            }
            else {
                let studentData = {
                    "date": shownStudents[index].date,
                    "timestamp": shownStudents[index].timestamp
                };
                var item = { "mode": mode, "student": studentData };
                students.push(item);
            }
        }

        const that = this;

        let session = await Auth.currentSession();
        let options = {
            body: students, // replace this with attributes you need
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': session.idToken.jwtToken
            }
        };
        API.post("APIGateway", "/modifystudentdata", options)
            .then(function (data) {
                if (data.hasOwnProperty('type') && data.type === "success") {
                    //Call function that updates students
                    that.refreshStatus("setRoadTestResultEmail");
                }
                else {
                    that.setState({ APIDateStudentResults: [] });
                    console.log("No students found");
                }
            }).catch(function (error) {
                console.log(error);
                if (error.response) {
                    if (error.response.status === 404) {
                        that.setState({ APIDateStudentResults: [] });
                    }
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    };

    handleSkipStudentLessons = async (mode) => {
        var selectedStudent = this.state.selectedLessonStudent;
        var students = [];
        let studentData = {
            "date": selectedStudent.date,
            "timestamp": selectedStudent.timestamp
        };
        var item = { "mode": mode, "student": studentData };
        students.push(item);

        const that = this;

        let session = await Auth.currentSession();
        let options = {
            body: students, // replace this with attributes you need
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': session.idToken.jwtToken
            }
        };
        API.post("APIGateway", "/modifystudentdata", options)
            .then(function (data) {
                if (data.hasOwnProperty('type') && data.type === "success") {
                    //Call function that updates students
                    that.refreshStatus(that.state.activeKey);
                }
                else {
                    that.setState({ APIDateStudentResults: [] });
                    console.log("No students found");
                }
            }).catch(function (error) {
                console.log(error);
                if (error.response) {
                    if (error.response.status === 404) {
                        that.setState({ APIDateStudentResults: [] });
                    }
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    };

    handleConfirmRegularScheduleLessons = async (mode) => {
        var selectedStudent = this.state.selectedLessonStudent;
        var students = [];
        let studentData = {
            "date": selectedStudent.date,
            "timestamp": selectedStudent.timestamp
        };
        var item = { "mode": mode, "student": studentData };
        students.push(item);

        const that = this;

        let session = await Auth.currentSession();
        let options = {
            body: students, // replace this with attributes you need
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': session.idToken.jwtToken
            }
        };
        API.post("APIGateway", "/modifystudentdata", options)
            .then(function (data) {
                if (data.hasOwnProperty('type') && data.type === "success") {
                    //Call function that updates students
                    that.refreshStatus(that.state.activeKey);
                }
                else {
                    that.setState({ APIDateStudentResults: [] });
                    console.log("No students found");
                }
            }).catch(function (error) {
                console.log(error);
                if (error.response) {
                    if (error.response.status === 404) {
                        that.setState({ APIDateStudentResults: [] });
                    }
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    };


    handleChangeRoadTestCertificateStatus = async (index, mode, usesStudentSlots) => {
        var students = [];
        var shownStudents = [];
        if (usesStudentSlots) {
            shownStudents = this.state.slots.filter(x => (Object.keys(x.value).length > 0 && x.value.constructor === Object)).map(x => x.value);
        } else {
            var shownStudents = this.state.unslotedMembers;
        }
        let studentData = {
            "date": shownStudents[index].date,
            "timestamp": shownStudents[index].timestamp
        };
        var item = { "mode": mode, "student": studentData };
        students.push(item);

        const that = this;

        let session = await Auth.currentSession();
        let options = {
            body: students, // replace this with attributes you need
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': session.idToken.jwtToken
            }
        };
        API.post("APIGateway", "/modifystudentdata", options)
            .then(function (data) {
                if (data.hasOwnProperty('type') && data.type === "success") {
                    //Call function that updates students
                    that.refreshStatus("viewCertStatus");
                }
                else {
                    that.setState({ APIDateStudentResults: [] });
                    console.log("No students found");
                }
            }).catch(function (error) {
                console.log(error);
                if (error.response) {
                    if (error.response.status === 404) {
                        that.setState({ APIDateStudentResults: [] });
                    }
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    };

    handleStartTimeChange(time) {
        this.setState({ startTime: time, endTime: time + 600 });
    }
    handleEndtimeTimeChange(time) {
        this.setState({ endTime: time });
    }

    handleSlotMove(index, movement) {
        if (movement === "up") { //move them up one.
            if (index !== 0) {//can't move up if at root
                var currPosition = index;
                var newPosition = index - 1;
                var slotLocalCopy = this.state.slots;
                var upperSlot = {
                    "slotName": slotLocalCopy[newPosition].slotName,
                    "person": slotLocalCopy[currPosition].person,
                    "value": slotLocalCopy[currPosition].value
                };
                var lowerSlot = {
                    "slotName": slotLocalCopy[currPosition].slotName,
                    "person": slotLocalCopy[newPosition].person,
                    "value": slotLocalCopy[newPosition].value
                };
                slotLocalCopy[newPosition] = upperSlot;
                slotLocalCopy[currPosition] = lowerSlot;
                this.setState({ slots: slotLocalCopy });

            }
            else {
                return;
            }
        }
        else if (movement === "down") { //move them up one.
            if (index !== this.state.slots.length - 1) {//can't move down if at end
                var currPosition1 = index;
                var newPosition1 = index + 1;
                var slotLocalCopy1 = this.state.slots;
                var upperSlot1 = {
                    "slotName": slotLocalCopy1[newPosition1].slotName,
                    "person": slotLocalCopy1[currPosition1].person,
                    "value": slotLocalCopy1[currPosition1].value
                };
                var lowerSlot1 = {
                    "slotName": slotLocalCopy1[currPosition1].slotName,
                    "person": slotLocalCopy1[newPosition1].person,
                    "value": slotLocalCopy1[newPosition1].value
                };
                slotLocalCopy1[newPosition1] = upperSlot1;
                slotLocalCopy1[currPosition1] = lowerSlot1;
                this.setState({ slots: slotLocalCopy1 });

            }
            else {
                return;
            }
        }
    }

    handleSlotClear(index) {
        var localSlots = this.state.slots;
        var personValue = localSlots[index].value;
        localSlots[index].value = {};
        localSlots[index].person = "";
        var unslotedMembers = this.state.unslotedMembers;
        if (Object.keys(personValue).length > 0 && personValue.constructor === Object) {
            unslotedMembers.push(personValue);
        }
        this.setState({ unslotedMembers: unslotedMembers });
    }

    handleSlotDelete(index) {
        var localSlots = this.state.slots;
        var personValue = localSlots[index].value;
        localSlots = localSlots.filter((item, i) => index !== i);
        var unslotedMembers = this.state.unslotedMembers;
        if (Object.keys(personValue).length > 0 && personValue.constructor === Object) {
            unslotedMembers.push(personValue);
        }
        this.setState({ unslotedMembers: unslotedMembers, slots: localSlots });
    }

    async handleSlotSave() {
        this.setState({ loading: true });
        var localSlots = this.state.slots.filter(x => (x.person));
        //We need to update all the slots with the right details.
        for (var pos = 0; pos < localSlots.length; pos++) {
            var slot = localSlots[pos];
            slot.value.slot = slot.slotName;
            slot.value.weekendDate = this.state.selectedWeekday;
            localSlots[pos] = slot;
        }

        this.setState({ slots: localSlots });

        //All values
        var allStudents = [];
        var tempStudents = localSlots.map(x => x.value);
        for (let index = 0; index < tempStudents.length; index++) {
            var student = tempStudents[index];
            let studentKeys = Object.keys(student);
            for (let studentIndex = 0; studentIndex < studentKeys.length; studentIndex++) {
                const key = studentKeys[studentIndex];
                if ((student[key] == null)) {
                    delete student[key];
                }
            }
            var item = { "mode": "addNewStudent", "student": student };
            allStudents.push(item);
        }

        const that = this;

        let session = await Auth.currentSession();
        let options = {
            body: allStudents, // replace this with attributes you need
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': session.idToken.jwtToken
            }
        };
        API.post("APIGateway", "/modifystudentdata", options)
            .then(function (data) {
                that.refreshStatus("sendSlotEmail");
                console.log(data);
            }).catch(function (error) {
                console.log(error);
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    }

    async handleUnslottedTimeSave() {
        this.setState({ loading: true });
        var localSlots = this.state.unslotedMembers;
        //All values
        var allStudents = [];
        for (let index = 0; index < localSlots.length; index++) {
            var student = localSlots[index];
            let studentKeys = Object.keys(student);
            for (let studentIndex = 0; studentIndex < studentKeys.length; studentIndex++) {
                const key = studentKeys[studentIndex];
                if ((student[key] == null)) {
                    delete student[key];
                }
            }
            var item = { "mode": "addNewStudent", "student": student };
            allStudents.push(item);
        }

        const that = this;

        let session = await Auth.currentSession();
        let options = {
            body: allStudents, // replace this with attributes you need
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': session.idToken.jwtToken
            }
        };
        API.post("APIGateway", "/modifystudentdata", options)
            .then(function (data) {
                that.refreshStatus("sendSlotEmail");
                console.log(data);
            }).catch(function (error) {
                console.log(error);
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    }

    async refreshStatus(newKey) {
        let selectedDay = this.state.selectedDay;
        var location = this.state.selectedLocation;
        let APIDateStudentResults = await this.handleGetSlotsbyDay(selectedDay);

        if (location.length === 0) {
            var selectedLocationSet = new Set(APIDateStudentResults.filter(x => x.location).map(x => x.location));
            var selectedDateLocations = [...selectedLocationSet];
            if (selectedDateLocations.length > 0) {
                location = selectedDateLocations[0];
            }
        }

        var filteredAPIDateStudentResults = APIDateStudentResults.filter(x => x.location === location);
        var preSlottedFolks = APIDateStudentResults.filter(x => x.slot).filter(x => x.location === location);
        if (preSlottedFolks.length > 0) {
            var slots = preSlottedFolks.map(function (x) { return { slotName: x.slot, person: x.fName + " " + x.lName, value: x }; });
            this.setState({ slots: this.sortSlots(slots, 'slotName') });
        }

        this.setState({ unslotedMembers: APIDateStudentResults.filter(x => !x.slot) });
        //The selected date is the first entry of the selectedDays
        this.setState({
            APIDateStudentResults,
            dataDirty: false,
            activeKey: newKey,
            filteredAPIDateStudentResults,
            selectedLessonStudent: filteredAPIDateStudentResults[this.state.selectedLessonStudentIndex],
            unslotedMembers: filteredAPIDateStudentResults.filter(x => !x.slot),
            refreshStatus: "Last refreshed at : " + moment().format('MMMM Do YYYY, h:mm:ss a'),
            loading: false

        });
    }

    generateSlots = async event => {
        var existingSlots = this.state.slots.map(x => x.slotName);
        var slots = [];

        var stateSlotNames = this.state.slots.map(x => x.slotName);


        for (var i = parseInt(this.state.startTime, 10); i <= parseInt(this.state.endTime, 10); i = i + 60 * this.state.slotTime) {
            var slotName = timeFromInt(i, { format: 12 });
            var data = {};
            if (existingSlots.includes(slotName)) {//We already have a filled out person at this time.
                var position = stateSlotNames.indexOf(slotName);
                data = this.state.slots[position];
            }
            else {
                data = {
                    slotName: timeFromInt(i, { format: 12 }),
                    person: "",
                    value: {}
                };
            }
            slots.push(data);
        }

        //We need to check if there are slots that were part of the original, and if they were not added, readd them.
        var missingOriginalsSlots = existingSlots.filter(e => !slots.map(x => x.slotName).includes(e));
        var missingOriginals = this.state.slots.filter(x => missingOriginalsSlots.includes(x.slotName));
        slots.concat(missingOriginals);
        //console.log(missingOriginals)

        this.setState({ slots: this.sortSlots(slots.concat(missingOriginals), 'slotName') });
    };

    generateUnslottedGroupTimes = async event => {
        var unslotedGroupTimes = this.state.unslotedGroupTimes;
        var stateSlotNames = this.state.unslotedGroupTimes.map(x => x.slotName);

        var slots = [];

        for (var i = parseInt(this.state.startTime, 10); i <= parseInt(this.state.endTime, 10); i = i + 60 * this.state.slotTime) {
            var slotName = timeFromInt(i, { format: 12 });
            var data = {};
            if (!stateSlotNames.includes(slotName)) {//We already have a filled out group at this time
                data = {
                    slotName: timeFromInt(i, { format: 12 }),
                };
                slots.push(data);
            }


        }

        //We need to check if there are slots that were part of the original, and if they were not added, readd them.
        var missingOriginalsSlots = stateSlotNames.filter(e => !slots.map(x => x.slotName).includes(e));
        var missingOriginals = this.state.unslotedGroupTimes.filter(x => missingOriginalsSlots.includes(x.slotName));
        slots.concat(missingOriginals);
        console.log(missingOriginals);

        this.setState({ unslotedGroupTimes: this.sortSlots(slots.concat(missingOriginals), 'slotName') });
    };

    fillLeftovers = async event => {
        this.setState({ dataDirty: true });
        var remainingslots = this.state.slots.filter(x => x.person === "").length;
        var existingSlots = this.state.slots.map(x => x.slotName);
        var stateSlotNames = this.state.slots.map(x => x.slotName);

        var startTime = 0;
        if (this.state.slots.length > 0) {
            startTime = timeToInt(this.state.slots[0].slotName);
            console.log(timeToInt(this.state.slots[0].slotName));
        }
        else {
            startTime = 25200;
        }

        var slots = [];
        if (remainingslots < this.state.unslotedMembers.length) {//There are not enough slot values. We need to create more slots.
            var difference = this.state.unslotedMembers.length - remainingslots - 1;
            console.log(difference);
            for (var i = startTime; i <= startTime + (difference * 600); i = i + 60 * this.state.slotTime) {
                var slotName = timeFromInt(i, { format: 12 });
                var data = {};
                if (existingSlots.includes(slotName)) {//We already have a filled out person at this time.
                    var position = stateSlotNames.indexOf(slotName);
                    data = this.state.slots[position];
                    difference++; //We already have this slot, so we need to add an extra slot
                }
                else {
                    data = {
                        slotName: timeFromInt(i, { format: 12 }),
                        person: "",
                        value: {}
                    };
                }

                slots.push(data);
            }
        }

        var missingOriginalsSlots = existingSlots.filter(e => !slots.map(x => x.slotName).includes(e));
        var missingOriginals = this.state.slots.filter(x => missingOriginalsSlots.includes(x.slotName));
        slots = this.sortSlots(slots.concat(missingOriginals), 'slotName');
        //Let's reverse the array so we can pop.
        var newSlots = [];
        var unslotedMembers = this.state.unslotedMembers.reverse();

        for (var j = 0; j < slots.length; j++) {
            var newdata = slots[j];
            if (unslotedMembers.length === 0) {
                newSlots.push(newdata);
                continue;
            }
            if (newdata.person) {
                newSlots.push(newdata);
            }
            else {
                var unslotedMembersData = unslotedMembers.pop();
                newdata.person = unslotedMembersData.fName + " " + unslotedMembersData.lName;
                newdata.value = unslotedMembersData;
                newSlots.push(newdata);
            }
        }

        this.setState({ slots: newSlots });
        console.log(remainingslots);
    };

    renderWeekdayChoices(date) {
        return (
            <div>
                Which day of the week is the Road Test on?

                <br></br>
                <Button className="padLeft" id="sat" disabled={this.state.selectedWeekday === "Saturday"} onClick={this.handleDayOfWeekButton}>Saturday</Button>
                <Button className="padLeft" id="sun" disabled={this.state.selectedWeekday === "Sunday"} onClick={this.handleDayOfWeekButton}>Sunday</Button>
                <Button className="padLeft" id="mon" disabled={this.state.selectedWeekday === "Monday"} onClick={this.handleDayOfWeekButton}>Monday</Button>
                <Button className="padLeft" id="tue" disabled={this.state.selectedWeekday === "Tuesday"} onClick={this.handleDayOfWeekButton}>Tuesday</Button>
                <Button className="padLeft" id="wed" disabled={this.state.selectedWeekday === "Wednesday"} onClick={this.handleDayOfWeekButton}>Wednesday</Button>
                <Button className="padLeft" id="thu" disabled={this.state.selectedWeekday === "Thursday"} onClick={this.handleDayOfWeekButton}>Thursday</Button>
                <Button className="padLeft" id="fri" disabled={this.state.selectedWeekday === "Friday"} onClick={this.handleDayOfWeekButton}>Friday</Button>
                <br></br>
                Warning: This will override ALL student dates in this block to the selected weekday. Make sure to save student data after updating the date!

            </div>
        );
    }

    async saveNewLessons() {
        let newLessons = this.state.newLessonsToAdd;
        let student = this.state.selectedLessonStudent;
        student.newLessons = newLessons;

        this.setState({ loading: true });

        var item = { "mode": "addSpecificLessons", "student": student };
        const that = this;

        let session = await Auth.currentSession();
        let options = {
            body: [item]
            ,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': session.idToken.jwtToken
            }
        };
        console.log(options);
        API.post("APIGateway", "/modifystudentdata", options)
            .then(async function (data) {
                if (data.hasOwnProperty('type') && data.type === "success") {
                    await that.refreshStatus(that.state.activeKey);
                    that.setState({ loading: false, newLessonsToAdd: [] });
                }
                else {
                    console.log("No students found");
                }
            }).catch(function (error) {
                console.log(error);
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    }


    async deleteOldLesson(index) {
        let student = this.state.selectedLessonStudent;
        console.log(student, index);
        let removedLesson = student.scheduledLessons.splice(index, 1);
        console.log(removedLesson, student);
        let newStudent = {
            date: student.date,
            timestamp: student.timestamp,
            scheduledLessons: student.scheduledLessons,
            removedLesson: removedLesson
        };

        this.setState({ loading: true });
        var item = { "mode": "deleteSpecificLesson", "student": newStudent };
        console.log(item);

        const that = this;

        let session = await Auth.currentSession();
        let options = {
            body: [item]
            ,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': session.idToken.jwtToken
            }
        };
        console.log(options);
        API.post("APIGateway", "/modifystudentdata", options)
            .then(function (data) {
                if (data.hasOwnProperty('type') && data.type === "success") {
                    that.refreshStatus(that.state.activeKey);
                    that.setState({ loading: false, showModal: false, modalView: "" });
                }
                else {
                    console.log("No students found");
                }
            }).catch(function (error) {
                console.log(error);
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    }


    async addAdditionalLessons() {
        var lessonName = this.state.CCLessonsWanted;
        lessonName += this.state.CCLessonsWanted == 1 ? " Extra Lesson" : " Extra Lessons";
        var student = this.state.selectedLessonStudent;
        var newLesson = { "name": lessonName, "price": parseInt(this.state.CCLessonsPrice) };
        if (student.hasOwnProperty("fullPricing")) {
            var fullPricing = student.fullPricing;
            fullPricing.push(newLesson);
            student.fullPricing = fullPricing;
        } else {
            student.fullPricing = [newLesson];
        }
        this.setState({ loading: true });
        var item = { "mode": "updateStudent", "student": student };
        const that = this;

        let session = await Auth.currentSession();
        let options = {
            body: [item]
            ,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': session.idToken.jwtToken
            }
        };
        console.log(options);
        API.post("APIGateway", "/modifystudentdata", options)
            .then(async function (data) {
                if (data.hasOwnProperty('type') && data.type === "success") {
                    await that.refreshStatus(that.state.activeKey);
                    that.setState({
                        loading: false, showAddLessons: false,
                        CCFirstName: "",
                        CCLastName: "",
                        CCExpiration: "",
                        CCCode: "",
                        CCAddress: "",
                        CCCity: "",
                        CCState: "",
                        CCZip: "",
                        CCCountry: "",
                        CCNumber: "",
                        CCLessonsWanted: 0,
                        CCLessonsPrice: 0,
                        CCErrors: "",
                        loading: false,
                        addLessonValidated: false,
                        skipExtraLessonsPayment: false
                    });
                }
                else {
                    console.log("No students found");
                }
            }).catch(function (error) {
                console.log(error);
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    }



    deleteNewLesson = index => {
        console.log(index);
        let newLessonsToAdd = this.state.newLessonsToAdd;
        newLessonsToAdd.splice(index, 1);
        this.setState({ newLessonsToAdd });
    };

    handleAddLessonDate = () => {
        let newLessonsToAdd = this.state.newLessonsToAdd;
        let selectedLessonValue = this.state.selectedLessonValue;
        if (Object.keys(selectedLessonValue).length === 0) {
            return;
        }

        if (newLessonsToAdd.filter(x => x.value === selectedLessonValue.value).length === 0) {
            newLessonsToAdd.push(selectedLessonValue);
        }

        this.setState({ newLessonsToAdd, selectedLessonValue: {} });

    };

    handleLessonDateChange = value => {
        console.log(value, this.state.newLessonsToAdd);
        this.setState({ selectedLessonValue: value });
    };

    loadRoadTestLessonDates = (inputValue, callback) => {
        let selectedLocation = this.state.selectedLocation;
        let keys = Object.keys(this.state.rawRoadTestLessons);
        let rawRoadTestLessons = this.state.rawRoadTestLessons;
        let foundLocations = keys.filter(date => rawRoadTestLessons[date].locations.includes(selectedLocation));

        console.log(selectedLocation, keys, rawRoadTestLessons);
        let foundLocationOptions = foundLocations.map(date => moment(date))
            .filter(x => moment().isBefore(x))
            .map(x => x.format("YYYY-MM-DD"))
            .map(x => { return { "value": x, "label": x }; });

        foundLocationOptions = foundLocationOptions.sort((a, b) => (a.value > b.value) ? 1 : -1);

        console.log(foundLocationOptions);
        callback(foundLocationOptions);
    };

    loadOptions = (inputValue, callback) => {
        let that = this;
        let roadTestDate = this.state.selectedDay;
        fetch('https://x4g3mwhate.execute-api.us-east-1.amazonaws.com/dev/getpubliclessondates', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'post',
            body: JSON.stringify({ "start": roadTestDate, "location": "West Roxbury" }
            )
        }).then(that.handleErrors)
            .then(function (response) {
                return response.json();
            }).then(function (data) {
                let allTimes = [];

                Object.keys(data).forEach(date => {
                    data[date].forEach(time => {
                        allTimes.push({
                            value: "West Roxbury" + "|" + date + "T" + time.startTime,
                            label: moment(date).format('ddd') + " " + moment(date).format("MM-DD-YYYY") + " at " + time.timeSlot,
                            date: date,
                            location: "West Roxbury",
                            slot: time.timeSlot

                        });
                    });

                });

                allTimes = allTimes.sort((a, b) => (a.value > b.value) ? 1 : -1);
                callback(allTimes);
            });
    };
    loadWalpoleOptions = (inputValue, callback) => {
        let that = this;
        let roadTestDate = this.state.selectedDay;
        fetch('https://x4g3mwhate.execute-api.us-east-1.amazonaws.com/dev/getpubliclessondates', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'post',
            body: JSON.stringify({ "start": roadTestDate, "location": "Walpole" }
            )
        }).then(that.handleErrors)
            .then(function (response) {
                return response.json();
            }).then(function (data) {
                let allTimes = [];

                Object.keys(data).forEach(date => {
                    data[date].forEach(time => {
                        allTimes.push({
                            value: "Walpole" + "|" + date + "T" + time.startTime,
                            label: moment(date).format('ddd') + " " + moment(date).format("MM-DD-YYYY") + " at " + time.timeSlot,
                            date: date,
                            location: "Walpole",
                            slot: time.timeSlot

                        });
                    });

                });

                allTimes = allTimes.sort((a, b) => (a.value > b.value) ? 1 : -1);
                callback(allTimes);
            });
    };

    renderLessonState() {
        let lessonsRequired = 0;
        let lessonsScheduled = [];
        let failedScheduledLessons = [];
        let newLessonsToAdd = this.state.newLessonsToAdd;
        if (Object.keys(this.state.selectedLessonStudent).length > 0) {
            let matchedPackage = this.state.avaliableOptions.filter(x => x.package === this.state.selectedLessonStudent.selectedPackage);
            if (matchedPackage.length === 1) {
                lessonsRequired = matchedPackage[0].lessons;
            }

            this.state.selectedLessonStudent.fullPricing.filter(x => x.name.includes("Extra Lesson")).map(x => x.name).map(x => lessonsRequired += parseInt(x.split(" ")[0]));

            if (this.state.selectedLessonStudent) {
                if (this.state.selectedLessonStudent.scheduledLessons) {
                    lessonsScheduled = this.state.selectedLessonStudent.scheduledLessons;
                }
                if (this.state.selectedLessonStudent.failedLessons) {
                    failedScheduledLessons = this.state.selectedLessonStudent.failedLessons;
                }
            }
        }

        return (
            <div>
                {newLessonsToAdd.length > 0 ?
                    <Alert variant="warning">
                        There are unsaved values. Please remember to save.
                    </Alert> : null}
                <div>
                    <strong>Student</strong>: {this.state.selectedLessonStudent.fName + " " + this.state.selectedLessonStudent.lName}
                </div>
                <div>
                    <strong>Lessons Needed</strong>: {lessonsRequired}
                </div>

                {lessonsScheduled.length + newLessonsToAdd.length < lessonsRequired ?
                    <div>
                        <Button disabled={this.state.selectedLessonLocation == "West Roxbury"} onClick={() => this.setState({ selectedLessonLocation: "West Roxbury", selectedLessonValue: {} })}>View West Roxbury Lessons</Button>
                        <Button disabled={this.state.selectedLessonLocation == "Walpole"} onClick={() => this.setState({ selectedLessonLocation: "Walpole", selectedLessonValue: {} })}>View Walpole Lessons</Button>

                        {this.state.selectedLessonLocation == "West Roxbury" ?
                            <div>
                                <div>West Roxbury</div>
                                <AsyncSelect
                                    key={this.state.selectedDay}
                                    value={this.state.selectedLessonValue}
                                    cacheOptions={false}
                                    loadOptions={this.loadOptions}
                                    defaultOptions
                                    noOptionsMessage={() => "No lessons avaliable for " + this.state.selectedDay + "."}
                                    onChange={this.handleLessonDateChange}
                                />
                                <Button disabled={Object.keys(this.state.selectedLessonValue).length === 0} onClick={() => this.handleAddLessonDate()}>Add Lesson</Button>

                            </div> : null}

                        {this.state.selectedLessonLocation == "Walpole" ?
                            <div>
                                <div>Walpole</div>
                                <AsyncSelect
                                    key={this.state.selectedDay}
                                    value={this.state.selectedLessonValue}
                                    cacheOptions={false}
                                    loadOptions={this.loadWalpoleOptions}
                                    defaultOptions
                                    noOptionsMessage={() => "No lessons avaliable for " + this.state.selectedDay + "."}
                                    onChange={this.handleLessonDateChange}
                                />
                                <Button disabled={Object.keys(this.state.selectedLessonValue).length === 0} onClick={() => this.handleAddLessonDate()}>Add Lesson</Button>

                            </div> : null}

                    </div> : null}
                <br></br>

                {newLessonsToAdd.length > 0 ?
                    <div>
                        <div>
                            <strong>
                                Pending Lessons to Add:
                            </strong>
                        </div>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Lesson Date</th>
                                    <th>Time Slot</th>
                                    <th>Location</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {newLessonsToAdd.map((lesson, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{lesson.date}</td>
                                            <td>{lesson.slot}</td>
                                            <td>{lesson.location}</td>
                                            <td><Button variant="danger" onClick={() => this.deleteNewLesson(index)}>Delete</Button></td>
                                        </tr>
                                    );
                                })
                                }
                            </tbody>
                        </Table>
                        <Button onClick={() => this.saveNewLessons()} disabled={this.state.loading} variant="primary">Save Lesson</Button>

                    </div> : null}
                <div>
                    <strong>Currently Scheduled Lessons</strong>
                </div>

                {lessonsScheduled.length > 0 ?
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Lesson Date</th>
                                <th>Time Slot</th>
                                <th>Location</th>
                                <th>Instructor</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lessonsScheduled.map((lesson, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{lesson.date}</td>
                                        <td>{lesson.slotName}</td>
                                        <td>{lesson.location}</td>
                                        <td>{lesson.instructor}</td>
                                        <td><Button variant="danger" onClick={() => this.deleteOldLesson(index)}>Delete</Button></td>
                                    </tr>
                                );
                            })
                            }
                        </tbody>
                    </Table>
                    : "No Lessons Scheduled"}
                <div>
                    <strong>Lessons with Scheduling Issues</strong>
                </div>
                {failedScheduledLessons.length > 0 ?

                    <div>
                        {failedScheduledLessons.map(lesson => {
                            return (<div>{lesson}</div>);
                        })}

                    </div> : "No Issues During Scheduling"}

                <br></br>
                <br></br>

                <Button variant="success" disabled={(this.state.selectedLessonStudent.hasOwnProperty("confirmScheduledRegular") && this.state.selectedLessonStudent.confirmScheduledRegular === true)} onClick={() => this.handleConfirmRegularScheduleLessons("confirmScheduledRegular")}>Confirm On Regular Schedule</Button>
                <Button variant="danger" disabled={(!this.state.selectedLessonStudent.hasOwnProperty("confirmScheduledRegular") || this.state.selectedLessonStudent.confirmScheduledRegular === false)} onClick={() => this.handleConfirmRegularScheduleLessons("undoConfirmScheduledRegular")}>Undo Regular Schedule Confirm</Button>

                <br></br>
                <br></br>

                <Button size="sm" variant="danger" disabled={(this.state.selectedLessonStudent.hasOwnProperty("skipStudentLessons") && this.state.selectedLessonStudent.skipStudentLessons === true)} onClick={() => this.handleSkipStudentLessons("skipStudentLessons")}>Skip Scheduling Lessons</Button>
                <Button size="sm" disabled={(!this.state.selectedLessonStudent.hasOwnProperty("skipStudentLessons") || this.state.selectedLessonStudent.skipStudentLessons === false)} onClick={() => this.handleSkipStudentLessons("undoSkipStudentLessons")}>Undo Skip Scheduling Lessons</Button>

                <br></br>
                <br></br>
                <Button size="sm" disabled={this.state.showAddLessons || this.state.loading} onClick={() => this.setState({ showAddLessons: true })}>Add Extra Lessons</Button>

                <br></br>
                <br></br>

                {this.state.showAddLessons ? <div>  <h3>Add Extra Lessons</h3>

                    <Form noValidate validated={this.state.addLessonValidated} onSubmit={this.handleAddNewLessons} >
                        <Form.Check inline label="Skip Payment" type={"checkbox"} id={`skipExtraLessonsPayment`} checked={this.state.skipExtraLessonsPayment} onChange={() => this.setState({ skipExtraLessonsPayment: !this.state.skipExtraLessonsPayment })} />
                        {!this.state.skipExtraLessonsPayment ? <div>
                            <InputGroup className="mb-3">
                                <Form.Control required
                                    name="CCNumber"
                                    type="text"
                                    value={this.state.CCNumber}
                                    placeholder="Card Number"
                                    onChange={this.handleModalInputChange}
                                />
                                <Form.Control.Feedback type="invalid">Please input a credit card number.</Form.Control.Feedback>

                            </InputGroup>

                            <Row>
                                <Col>
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control required
                                        name="CCFirstName"
                                        type="text"
                                        value={this.state.CCFirstName}
                                        placeholder="First Name"
                                        onChange={this.handleModalInputChange}
                                    />
                                    <Form.Control.Feedback type="invalid">Please input a first name.</Form.Control.Feedback>
                                </Col>
                                <Col>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control required
                                        name="CCLastName"
                                        type="text"
                                        value={this.state.CCLastName}
                                        placeholder="Last Name"
                                        onChange={this.handleModalInputChange}
                                    />
                                    <Form.Control.Feedback type="invalid">Please input a last name.</Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId="CCExpiration">
                                        <Form.Label>MM/YY</Form.Label>
                                        <Form.Control required
                                            name="CCExpiration"
                                            type="text"
                                            value={this.state.CCExpiration}
                                            placeholder="MM/YY"
                                            onChange={this.handleModalInputChange}
                                        />
                                        <Form.Control.Feedback type="invalid">Please input the credit card's expiration date.</Form.Control.Feedback>
                                    </Form.Group>

                                </Col>
                                <Col>
                                    <Form.Group controlId="CCCode">
                                        <Form.Label>CVC</Form.Label>
                                        <Form.Control required
                                            name="CCCode"
                                            type="text"
                                            value={this.state.CCCode}
                                            placeholder="CVC"
                                            onChange={this.handleModalInputChange}
                                        />
                                        <Form.Control.Feedback type="invalid">Please input the credit card's CVC.</Form.Control.Feedback>
                                    </Form.Group>

                                </Col>
                            </Row>
                            <Form.Group controlId="CCAddress">
                                <Form.Label>Billing Address</Form.Label>
                                <Form.Control required
                                    name="CCAddress"
                                    type="text"
                                    value={this.state.CCAddress}
                                    placeholder=""
                                    onChange={this.handleModalInputChange}
                                />
                                <Form.Control.Feedback type="invalid">Please input the credit card's billing address.</Form.Control.Feedback>
                            </Form.Group>

                            <Row>
                                <Col>
                                    <Form.Label>City</Form.Label>
                                    <Form.Control required
                                        name="CCCity"
                                        type="text"
                                        value={this.state.CCCity}
                                        placeholder="City"
                                        onChange={this.handleModalInputChange}
                                    />
                                    <Form.Control.Feedback type="invalid">Please input a city.</Form.Control.Feedback>
                                </Col>
                                <Col>
                                    <Form.Label>State</Form.Label>
                                    <Form.Control required
                                        name="CCState"
                                        type="text"
                                        value={this.state.CCState}
                                        placeholder="State"
                                        onChange={this.handleModalInputChange}
                                    />
                                    <Form.Control.Feedback type="invalid">Please input a state.</Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Zip</Form.Label>
                                    <Form.Control required
                                        name="CCZip"
                                        type="text"
                                        value={this.state.CCZip}
                                        placeholder="Zip"
                                        onChange={this.handleModalInputChange}
                                    />
                                    <Form.Control.Feedback type="invalid">Please input a zip.</Form.Control.Feedback>
                                </Col>
                                <Col>
                                    <Form.Label>Country</Form.Label>

                                    <Form.Control required
                                        name="CCCountry"
                                        type="text"
                                        value={this.state.CCCountry}
                                        placeholder="Country"
                                        onChange={this.handleModalInputChange}
                                    />
                                    <Form.Control.Feedback type="invalid">Please input a country.</Form.Control.Feedback>
                                </Col>
                            </Row>


                        </div> : <div></div>}
                        <Row>
                            <Col>
                                <Form.Label>Lessons Wanted</Form.Label>

                                <Form.Control required
                                    name="CCLessonsWanted"
                                    type="number"
                                    value={this.state.CCLessonsWanted}
                                    placeholder="Lessons Wanted"
                                    onChange={this.handleModalInputChange}
                                />
                                <Form.Control.Feedback type="invalid">Please input the lessons wanted.</Form.Control.Feedback>
                            </Col>
                            <Col>
                                <Form.Label>Total Price</Form.Label>
                                <InputGroup>
                                    <InputGroup.Text>$</InputGroup.Text>

                                    <Form.Control required
                                        name="CCLessonsPrice"
                                        type="number"
                                        value={this.state.CCLessonsPrice}
                                        placeholder="Total Price"
                                        onChange={this.handleModalInputChange}
                                    />
                                    <Form.Control.Feedback type="invalid">Please input the total price of new lessons.</Form.Control.Feedback>
                                </InputGroup>

                            </Col>

                        </Row>
                        <br></br>
                        <Button disabled={this.state.loading} type="submit">Add</Button>
                        <br></br>
                        <br></br>



                    </Form></div> : null}
                {this.state.CCErrors ? <Alert variant="warning">
                    <strong>{this.state.CCErrors}</strong>
                </Alert> : null}

            </div>



        );

    }


    renderUnslottedStudentTimes() {

        let unslotedGroupTimes = this.state.unslotedGroupTimes;
        let unslotedMembers = this.state.unslotedMembers;

        let timesToStudentMap = new Map();

        let studentsWithoutSlots = [];

        for (let i = 0; i < unslotedMembers.length; i++) {
            var student = unslotedMembers[i];
            if (student.hasOwnProperty("unslotTime")) {
                var slotName = student.unslotTime;

                if (timesToStudentMap.has(slotName)) {
                    var existingStudents = timesToStudentMap.get(slotName);
                    existingStudents.push(student);
                    timesToStudentMap.set(slotName, existingStudents);
                } else {
                    timesToStudentMap.set(slotName, [student]);

                }
            } else (
                studentsWithoutSlots.push(student)
            );
        }
        return (
            <div>
                {studentsWithoutSlots.length > 0 ? <div>There {studentsWithoutSlots.length === 1 ? <span>is</span> : <span>are</span>} {studentsWithoutSlots.length} student{studentsWithoutSlots.length === 1 ? null : 's'} remaining. </div>
                    : <div>Looks like all students have been slotted.</div>
                }
                {this.state.unslotedGroupTimes.length === 0 ? <div>There are currently no slots. Please generate some to start filling in students.</div> :
                    <div>
                        <Table striped bordered hover responsive size="sm">
                            <thead>
                                <tr>
                                    <th className="col-1">Time</th>
                                    <th className="col-6">Students</th>
                                    <th className="col-4">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {unslotedGroupTimes.map(x => x.slotName).map((time, index) => {
                                    if (timesToStudentMap.has(time)) {
                                        return (
                                            <tr key={index}>
                                                <td className="col-1">{time}</td>
                                                <td className="col-6"> Selected Student
                                                    <Select
                                                        isMulti
                                                        value={
                                                            timesToStudentMap.get(time).map(student => (
                                                                {
                                                                    label: student.fName + " " + student.lName,
                                                                    value: student
                                                                }
                                                            )
                                                            )
                                                        } styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                        onChange={(data) => this.handleUnslottedStudentPick(data, time, index)}
                                                        options={studentsWithoutSlots.map(function (y) { return { value: y, label: y.fName + " " + y.lName }; })} />
                                                </td>
                                                <td><Button variant="danger" disabled={this.state.loading == true} onClick={() => this.subtractUnslottedTime(time)}>Delete</Button></td>
                                            </tr>
                                        );
                                    } else {
                                        return (
                                            <tr key={index}>
                                                <td className="col-1">{time}</td>

                                                <td className="col-6"> Selected Student
                                                    <Select
                                                        isMulti
                                                        value styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                        onChange={(data) => this.handleUnslottedStudentPick(data, time, index)}
                                                        options={studentsWithoutSlots.map(function (y) { return { value: y, label: y.fName + " " + y.lName }; })} />
                                                </td>
                                                <td><Button variant="danger" onClick={() => this.subtractUnslottedTime(time)}>Delete</Button></td>
                                            </tr>
                                        );
                                    }
                                })
                                }

                            </tbody>
                        </Table>
                    </div>

                }
            </div>

        );
    }



    handleSetLocationAndGetData = async (location) => {
        this.setState({ selectedLocation: location }, () => this.processLocationSlots());
    };

    render() {
        const { activeKey, selectedDays, selectedDay, dayOfExam, selectedDaysRange, selectedWeekday, APIDateStudentResults, filteredAPIDateStudentResults, addModalValidated } = this.state;

        const timeOptions = [
            { "label": "5 Minutes", "value": 5 },
            { "label": "10 Minutes", "value": 10 },
            { "label": "15 Minutes", "value": 15 },
            { "label": "30 Minutes", "value": 30 },
            { "label": "45 Minutes", "value": 45 },
            { "label": "60 Minutes", "value": 60 }

        ];

        return (
            <div className="holder">

                <h3>Manage Appointments</h3>

                Please select the weekend/week you want to manage appointments for.
                <br></br>

                <Accordion defaultActiveKey="selectDays" activeKey={activeKey}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="selectDays" onClick={() => this.setState({ activeKey: "selectDays" })}>
                            {this.state.selectedDays.length > 0 ? selectedDaysRange : "Select Days"}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="selectDays">
                            <Card.Body>
                                <div>

                                    <DayPicker
                                        numberOfMonths={2}
                                        selectedDays={selectedDays}
                                        showOutsideDays
                                        onDayClick={this.handleDayClick}
                                    />
                                    <br></br>
                                    {!this.state.fetchedStudents && selectedDays.length > 0 ? "Please wait, loading students." : null}
                                    {this.state.fetchedStudents ?
                                        <div className="padLeft">
                                            {this.state.selectedLocation.length > 0 ? <div>Now viewing lessons for: {this.state.selectedLocation}</div> : <div>Please select the road test location</div>}
                                            {this.state.locations.map((location, index) => {
                                                return (
                                                    <Button disabled={this.state.selectedLocation === location} onClick={() => this.handleSetLocationAndGetData(location)}>{location}</Button>
                                                );
                                            })}
                                            <hr></hr>
                                            <Button disabled={this.state.loadingDay || this.state.selectedDay.length === 0} onClick={this.handleFirstNextButton}>{this.state.loadingDay ? "Please wait. Loading students..." : "Slot Students"}</Button>
                                            <Button disabled={this.state.loadingDay || this.state.selectedDay.length === 0} onClick={() => this.setState({ activeKey: "manageStudents" })}>{this.state.loadingDay ? "Please wait. Loading students..." : "Add/Move/Delete Students"}</Button>

                                            <Button bsStyle="primary" disabled={this.state.loading || this.state.APIDateStudentResults.length === 0} onClick={this.handleFilterDownload}>Download Table Data</Button>
                                            <CSVLink ref={(r) => this.filterCSVLink = r} data={this.state.APIDateStudentResults} headers={headers} className="hidden" filename={moment(this.state.selectedDay).format("YYYY_MM_DD_") + this.state.selectedLocation + "_StudentReport.csv"}></CSVLink>

                                        </div>
                                        : null}



                                </div>
                                {selectedDay ?
                                    <div className="padLeft">
                                        {this.state.fetchedStudents ?
                                            <div>
                                                {this.state.filteredAPIDateStudentResults ?
                                                    this.state.filteredAPIDateStudentResults.length > 0 ? <div>Loaded {this.state.filteredAPIDateStudentResults.length} student{this.state.filteredAPIDateStudentResults.length === 1 ? null : 's'}. </div>
                                                        : <div>
                                                            {this.state.selectedLocation.length === 0 && this.state.APIDateStudentResults.length > 0 ?
                                                                <div>There are multiple locations with students on this date. Please select a location. </div>
                                                                :
                                                                <div>No students found in this selection.</div>
                                                            }
                                                        </div> : null
                                                }
                                            </div> : null
                                        }
                                    </div> : null
                                }
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="viewLessonStatus" onClick={() => this.setState({ activeKey: "viewLessonStatus" })}>
                            View Road Test Lesson Status
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="viewLessonStatus">
                            <Card.Body>
                                {activeKey === "viewLessonStatus" ? <div>
                                    {this.state.EmailError ? <Alert variant="warning">
                                        <strong>{this.state.EmailError}</strong>
                                    </Alert> : null}
                                    {this.state.refreshStatus ? <Alert variant="success">
                                        <strong>{this.state.refreshStatus}</strong>
                                    </Alert> : null}
                                </div> : null}


                                {this.state.selectedDays.length > 0 ?
                                    <div>
                                        {this.state.selectedLocation.length === 0 ?
                                            <div>
                                                You have not selected a road test location. Please select a location first!
                                                <div className="padLeft">
                                                    {this.state.selectedLocation.length > 0 ? <div>Now viewing lessons for: {this.state.selectedLocation}</div> :
                                                        null}
                                                    <Button disabled={this.state.selectedLocation === "West Roxbury"} onClick={() => this.handleSetLocationAndGetData("West Roxbury")}>West Roxbury</Button>
                                                    <Button disabled={this.state.selectedLocation === "Walpole"} onClick={() => this.handleSetLocationAndGetData("Walpole")}>Walpole</Button>
                                                </div>

                                            </div> :
                                            <div>
                                                {this.state.filteredAPIDateStudentResults.length === 0 ?
                                                    <div>
                                                        <div>No students found. Please select a new day, or add some students.</div>
                                                        <br></br>
                                                        <Button variant="primary" onClick={() => this.setState({ activeKey: "selectDays" })}>
                                                            Select New Day Range
                                                        </Button>
                                                        <Button variant="primary" onClick={() => this.setState({ activeKey: "manageStudents" })}>
                                                            Add Some Students
                                                        </Button>
                                                        <br></br>
                                                    </div>
                                                    :
                                                    <div>
                                                        <Button className="rightBut" disabled={this.state.refreshing || this.state.dataDirty} onClick={() => this.refreshStatus(this.state.activeKey)}>  Refresh Status </Button>
                                                        <br></br><br></br>
                                                        {!this.state.dataDirty ?
                                                            <Table striped bordered hover size="sm">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Name</th>
                                                                        <th>Package</th>
                                                                        <th>Lessons Scheduled</th>
                                                                        <th>Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.filteredAPIDateStudentResults.map((x, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{x.fName + " " + x.lName}</td>
                                                                                <td>{x.selectedPackage}
                                                                                    {x.fullPricing.filter(x => x.name.includes("Extra Lesson")).map(x => x.name).map(name => { return (<div>{name}</div>); })}
                                                                                </td>
                                                                                <td>
                                                                                    {x.selectedPackage.includes("Lesson") || x.hasOwnProperty("scheduledLessons") && x.scheduledLessons.length > 0 ?
                                                                                        <div>
                                                                                            {x.skipStudentLessons ? "N/A" :
                                                                                                <div>
                                                                                                    {x.scheduledLessons && x.scheduledLessons.length > 0 ?
                                                                                                        <div>
                                                                                                            {x.scheduledLessons.map(lesson => {
                                                                                                                return (
                                                                                                                    <div>
                                                                                                                        {moment(lesson.date).format("MM/DD/YYYY") + " at " + lesson.slotName + " in " + lesson.location + " with " + lesson.instructor}
                                                                                                                    </div>
                                                                                                                );
                                                                                                            })}

                                                                                                        </div> : "No Lessons Scheduled "
                                                                                                    }
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                        : "N/A"
                                                                                    }
                                                                                </td>

                                                                                <td>
                                                                                    <Button variant={x.confirmScheduledRegular === true ? "success" : "primary"} onClick={() => this.handleLessonModalChange(true, index)} size="sm">Manage Lessons</Button>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                </tbody>
                                                            </Table> : null}


                                                    </div>}
                                            </div>
                                        }
                                    </div>
                                    : <div>
                                        <div>A date range was not selected. Please select a date range in order to pick a day of the week.</div>
                                        <br></br>
                                        <Button variant="primary" onClick={() => this.setState({ activeKey: "selectDays" })}>Select New Day Range</Button>
                                        <br></br>
                                    </div>}
                            </Card.Body>

                        </Accordion.Collapse>
                    </Card>



                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="manageStudents" onClick={() => this.setState({ activeKey: "manageStudents" })}>
                            Add/Move/Delete Students
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="manageStudents">
                            <Card.Body>
                                {this.state.refreshStatus ? <Alert variant="success">
                                    <strong>{this.state.refreshStatus}</strong>
                                </Alert> : null}
                                {activeKey === "manageStudents" ?
                                    <div>

                                    </div> : null}


                                {this.state.selectedDays.length > 0 ?


                                    <div>
                                        {this.state.selectedLocation.length === 0 ?
                                            <div>
                                                You have not selected a road test location. Please select a location first!
                                                <div className="padLeft">
                                                    {this.state.selectedLocation.length > 0 ? <div>Now viewing lessons for: {this.state.selectedLocation}</div> :
                                                        null}
                                                    <Button disabled={this.state.selectedLocation === "West Roxbury"} onClick={() => this.handleSetLocationAndGetData("West Roxbury")}>West Roxbury</Button>
                                                    <Button disabled={this.state.selectedLocation === "Walpole"} onClick={() => this.handleSetLocationAndGetData("Walpole")}>Walpole</Button>
                                                </div>

                                            </div> :

                                            <div>
                                                {this.state.fetchedStudents ?
                                                    <div>
                                                        <h4>Add Students</h4>

                                                        <Button className="padLeft" onClick={() => this.handleModalChange(true, "add")}>Open Add New Student Modal</Button>

                                                        <br></br>
                                                        <h4>Unsaved Students</h4>
                                                        {this.state.unsavedManuallyAddedStudents.length > 0 ?
                                                            <div>
                                                                <Button className="padLeft" variant="success" disabled={this.state.loading} onClick={this.addNewStudents}>Save New Students</Button>
                                                                <Table striped bordered hover responsive size="sm">
                                                                    <thead>
                                                                        <tr className="d-flex">
                                                                            <th className="col-4">Package</th>
                                                                            <th className="col-4">First Name</th>
                                                                            <th className="col-4">Last Name</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.unsavedManuallyAddedStudents.map((x, index) => {
                                                                            return (
                                                                                <tr key={index} className="d-flex">
                                                                                    <td className="col-4">{x.student.selectedPackage}</td>
                                                                                    <td className="col-4">{x.student.fName}</td>
                                                                                    <td className="col-4">{x.student.lName}</td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                </Table>
                                                                <br></br>
                                                            </div>

                                                            : <div>
                                                                <br></br>
                                                                There are no unsaved students.
                                                            </div>
                                                        }

                                                        <h4>Current Students</h4>
                                                        <Button className="rightBut" disabled={this.state.refreshing || this.state.dataDirty} onClick={() => this.refreshStatus(this.state.activeKey)}>  Refresh Status </Button>

                                                        {filteredAPIDateStudentResults.length > 0 ?
                                                            <div>
                                                                <Table striped bordered hover responsive size="sm">
                                                                    <thead>
                                                                        <tr className="d-flex">
                                                                            <th className="col-4">Package</th>
                                                                            <th className="col-4">First Name</th>
                                                                            <th className="col-4">Last Name</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {filteredAPIDateStudentResults.map((x, index) => {
                                                                            return (
                                                                                <tr key={index} className="d-flex">
                                                                                    <td className="col-4">{x.selectedPackage}</td>
                                                                                    <td className="col-4">{x.fName}</td>
                                                                                    <td className="col-4">{x.lName}</td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                </Table>
                                                                <br></br>
                                                                <Button variant="secondary" className="padLeft" onClick={() => this.handleModalChange(true, "move")}>Move Student</Button>
                                                                <Button variant="secondary" className="padLeft" onClick={() => this.handleModalChange(true, "copy")}>Copy Student</Button>

                                                                <Button variant="danger" className="padLeft" onClick={() => this.handleModalChange(true, "delete")}>Delete Student</Button>


                                                            </div> : <div><br></br>There are no saved students. </div>}


                                                    </div> :
                                                    <div>
                                                        A date was not selected. Please select a date to add/move/delete students.
                                                        <br></br>
                                                        <Button variant="primary" onClick={() => this.setState({ activeKey: "selectDays" })}>
                                                            Select New Day Range
                                                        </Button>
                                                    </div>
                                                }

                                            </div>}



                                    </div>

                                    : <div>
                                        <div>A date range was not selected. Please select a date range in order to pick a day of the week.</div>
                                        <br></br>
                                        <Button variant="primary" onClick={() => this.setState({ activeKey: "selectDays" })}>Select New Day Range</Button>
                                        <br></br>
                                    </div>}




                            </Card.Body>

                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="sendInitialEmail" onClick={() => this.setState({ activeKey: "sendInitialEmail" })}>
                            Send Inital Emails
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="sendInitialEmail">
                            <Card.Body>

                                {this.state.selectedDays.length > 0 ?
                                    <div>
                                        {this.state.selectedLocation.length === 0 ?
                                            <div>
                                                <p>You have not selected a road test location. Please select a location first!</p>
                                                <div className="padLeft">
                                                    {this.state.selectedLocation.length > 0 ? <div>Now viewing lessons for: {this.state.selectedLocation}</div> :
                                                        null}
                                                    <Button disabled={this.state.selectedLocation === "West Roxbury"} onClick={() => this.handleSetLocationAndGetData("West Roxbury")}>West Roxbury</Button>
                                                    <Button disabled={this.state.selectedLocation === "Walpole"} onClick={() => this.handleSetLocationAndGetData("Walpole")}>Walpole</Button>
                                                </div>

                                            </div> :
                                            <div>
                                                {this.state.filteredAPIDateStudentResults.length === 0 ?
                                                    <div>
                                                        <div>No students found. Please select a new day, or add some students.</div>
                                                        <br></br>
                                                        <Button variant="primary" onClick={() => this.setState({ activeKey: "selectDays" })}>
                                                            Select New Day Range
                                                        </Button>
                                                        <Button variant="primary" onClick={() => this.setState({ activeKey: "manageStudents" })}>
                                                            Add Some Students
                                                        </Button>
                                                        <br></br>
                                                    </div>
                                                    :
                                                    <div>
                                                        <div>
                                                            {this.state.EmailError ? <Alert variant="warning">
                                                                <strong>{this.state.EmailError}</strong>
                                                            </Alert> : null}
                                                            {this.state.refreshStatus ? <Alert variant="success">
                                                                <strong>{this.state.refreshStatus}</strong>
                                                            </Alert> : null}
                                                        </div>
                                                        <Button disabled={this.state.dataDirty || this.state.fetchingStudents || this.state.loading == true} onClick={() => this.sendInitalEmailBulk([])}> Send all Unsent Inital Emails </Button>

                                                        <Button className="rightBut" disabled={this.state.refreshing || this.state.dataDirty} onClick={() => this.refreshStatus(this.state.activeKey)}>  Refresh Status </Button>
                                                        <br></br><br></br>
                                                        {!this.state.dataDirty ?

                                                            <Table striped bordered hover size="sm">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Name</th>
                                                                        <th>Inital Email</th>
                                                                        <th>Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.filteredAPIDateStudentResults.map((x, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{x.fName + " " + x.lName}</td>
                                                                                <td>{x.emailInitalState}</td>
                                                                                <td>
                                                                                    <Button disabled={this.state.dataDirty || this.state.loading} onClick={() => this.sendInitalEmailBulk([x])} size="sm">Send Inital Email</Button>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                </tbody>
                                                            </Table> : null}


                                                    </div>}
                                            </div>
                                        }
                                    </div>
                                    : <div>
                                        <div>A date range was not selected. Please select a date range in order to pick a day of the week.</div>
                                        <br></br>
                                        <Button variant="primary" onClick={() => this.setState({ activeKey: "selectDays" })}>Select New Day Range</Button>
                                        <br></br>
                                    </div>}
                            </Card.Body>

                        </Accordion.Collapse>
                    </Card>

                    {this.state.selectedLocation.includes("RMV Site") ? <div>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="slotStudents" onClick={this.handleFirstNextButton}>
                                Slotting Weekday Students
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="slotStudents">
                                <Card.Body>
                                    {activeKey === "slotStudents" ?
                                        <div>

                                            <div>
                                                <div>
                                                    {this.state.unsavedManuallyAddedStudents.length > 0 ?
                                                        <div>
                                                            <div>Warning. You have unsaved manually added students from earlier. Please save these students first.</div>
                                                            <Button className="padLeft" variant="success" onClick={this.addNewStudents}>Save New Students</Button>
                                                        </div>

                                                        : null
                                                    }

                                                    {this.state.unslotedGroupTimes.length === 0 ? null : <div>   <div>Selected Groups Duration</div>
                                                        {this.state.unslotedGroupTimes.map((selectedGroupTime) => {
                                                            return <Button size="sm" variant="info">{selectedGroupTime.slotName}</Button>;
                                                        })}
                                                    </div>}

                                                    <div>Select Duration of Each Slot</div>
                                                    <Select
                                                        value={this.state.selectedTimeSlot}
                                                        onChange={(data) => this.handleSlotDurationChange(data)}
                                                        options={timeOptions}
                                                    />
                                                    <br></br>

                                                    <div>Generate/View Slots for {moment(dayOfExam).format("dddd, MMMM Do YYYY")}</div>
                                                    <br></br>
                                                    <Row>
                                                        <Col>
                                                            <TimePicker start="5:00" end="21:00" step={this.state.slotTime} onChange={this.handleStartTimeChange} value={this.state.startTime} />
                                                        </Col>
                                                        <Col sm={1}>to</Col>
                                                        <Col>
                                                            <TimePicker start={timeFromInt(parseInt(this.state.startTime) + (60 * this.state.slotTime))} end="21:00" step={this.state.slotTime} onChange={this.handleEndtimeTimeChange} value={this.state.endTime} />
                                                        </Col>
                                                    </Row>
                                                    <br></br>
                                                    <Button className="padLeft" onClick={this.generateUnslottedGroupTimes}>Generate Group Time Slots</Button>

                                                    {this.renderUnslottedStudentTimes()}


                                                </div>

                                                <div>


                                                    <Button variant="primary" className="padLeft" disabled={this.state.loading || selectedWeekday.length === 0} onClick={this.handleUnslottedTimeSave}> Save Slots </Button>

                                                    {selectedWeekday ? null : <div>Please Confirm Day of Week for Exam First</div>}

                                                </div>
                                            </div>

                                        </div> : null}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>




                    </div> : <div>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="confirmWeekdayDate" onClick={() => this.setState({ activeKey: "confirmWeekdayDate" })}>
                                {selectedWeekday ? <div>{selectedWeekday}</div> : "Confirm Day of Week for Exam"}
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="confirmWeekdayDate">
                                <Card.Body>
                                    {activeKey === "confirmWeekdayDate" ?
                                        <div>
                                            {selectedDay ?
                                                <div>
                                                    {this.renderWeekdayChoices(selectedDay)}
                                                </div>
                                                : <div>
                                                    <div>A date range was not selected. Please select a date range in order to pick a day of the week.</div>
                                                    <br></br>
                                                    <Button variant="primary" onClick={() => this.setState({ activeKey: "selectDays" })}>
                                                        Select New Day Range
                                                    </Button>

                                                    <br></br>
                                                </div>}

                                        </div>

                                        : null}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="slotStudents" onClick={this.handleFirstNextButton}>
                                Slotting Students
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="slotStudents">
                                <Card.Body>
                                    {activeKey === "slotStudents" ?
                                        <div>
                                            {selectedDay ?
                                                <div>
                                                    {this.state.selectedLocation.length === 0 ?
                                                        <div>
                                                            You have not selected a road test location. Please select a location first!
                                                            <div className="padLeft">
                                                                {this.state.selectedLocation.length > 0 ? <div>Now viewing lessons for: {this.state.selectedLocation}</div> :
                                                                    null}
                                                                <Button disabled={this.state.selectedLocation === "West Roxbury"} onClick={() => this.handleSetLocationAndGetData("West Roxbury")}>West Roxbury</Button>
                                                                <Button disabled={this.state.selectedLocation === "Walpole"} onClick={() => this.handleSetLocationAndGetData("Walpole")}>Walpole</Button>
                                                            </div>

                                                        </div> :
                                                        <div>
                                                            {this.state.selectedLocation.includes("RMV Site") ?
                                                                <div>
                                                                    RMV Road Test Sites do not have slots. The email will be sent out requesting that folks show up at 7:45 am on the date.
                                                                </div> :
                                                                <div>
                                                                    <div>
                                                                        {this.state.unsavedManuallyAddedStudents.length > 0 ?
                                                                            <div>
                                                                                <div>Warning. You have unsaved manually added students from earlier. Please save these students first.</div>
                                                                                <Button className="padLeft" variant="success" onClick={this.addNewStudents}>Save New Students</Button>
                                                                            </div>

                                                                            : null
                                                                        }
                                                                        <div>Select Duration of Each Slot</div>
                                                                        <Select
                                                                            value={this.state.selectedTimeSlot}
                                                                            onChange={(data) => this.handleSlotDurationChange(data)}
                                                                            options={timeOptions}
                                                                        />
                                                                        <br></br>

                                                                        <div>Generate/View Slots for {moment(dayOfExam).format("dddd, MMMM Do YYYY")}</div>
                                                                        <br></br>
                                                                        <Row>
                                                                            <Col>
                                                                                <TimePicker start="5:00" end="21:00" step={this.state.slotTime} onChange={this.handleStartTimeChange} value={this.state.startTime} />
                                                                            </Col>
                                                                            <Col sm={1}>to</Col>
                                                                            <Col>
                                                                                <TimePicker start={timeFromInt(parseInt(this.state.startTime) + (60 * this.state.slotTime))} end="21:00" step={this.state.slotTime} onChange={this.handleEndtimeTimeChange} value={this.state.endTime} />
                                                                            </Col>
                                                                        </Row>
                                                                        <br></br>
                                                                        <Button className="padLeft" onClick={this.generateSlots}>Generate Slots</Button>
                                                                        {this.state.unslotedMembers.length > 0 ? <div>There {this.state.unslotedMembers.length === 1 ? <span>is</span> : <span>are</span>} {this.state.unslotedMembers.length} student{this.state.unslotedMembers.length === 1 ? null : 's'} remaining. </div>
                                                                            : <div>Looks like all students have been slotted.</div>
                                                                        }
                                                                    </div>

                                                                    <div>
                                                                        <hr></hr>

                                                                        {this.state.slots.length === 0 ? <div>There are currently no slots. Please generate some to start filling in students.</div> : null}
                                                                        <br></br>
                                                                        <Table striped bordered hover responsive size="sm">
                                                                            <thead>
                                                                                <tr className="d-flex">
                                                                                    <th className="col-1">#</th>
                                                                                    <th className="col-1">Time</th>
                                                                                    <th className="col-6">Students</th>
                                                                                    <th className="col-4">Actions</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {this.state.slots.map((x, index) => {
                                                                                    return (
                                                                                        <tr key={index} className="d-flex">
                                                                                            <td className="col-1">{index + 1}</td>
                                                                                            <td className="col-1">{x.slotName}</td>
                                                                                            <td className="col-6">
                                                                                                <Select
                                                                                                    value={{
                                                                                                        label: this.state.slots[index].person,
                                                                                                        value: this.state.slots[index].value
                                                                                                    }}
                                                                                                    onChange={(data) => this.handleStudentPick(data, index)}
                                                                                                    options={this.state.unslotedMembers.map(function (x) { return { value: x, label: x.fName + " " + x.lName }; })}
                                                                                                /></td>
                                                                                            <td className="col-4">
                                                                                                <Button variant="secondary" disabled={index === 0} onClick={() => this.handleSlotMove(index, "up")} size="sm">Move Up</Button>
                                                                                                <Button variant="secondary" disabled={index === this.state.slots.length - 1} onClick={() => this.handleSlotMove(index, "down")} size="sm">Move Down</Button>
                                                                                                <Button variant="secondary" disabled={this.state.slots[index].person.length === 0} onClick={() => this.handleSlotClear(index)} size="sm">Clear Slot</Button>
                                                                                                <Button variant="danger" onClick={() => this.handleSlotDelete(index)} size="sm">Delete Slot</Button>
                                                                                            </td>

                                                                                        </tr>
                                                                                    );
                                                                                })}
                                                                            </tbody>
                                                                        </Table>

                                                                        <Button variant="primary" className="padLeft" disabled={this.state.loading || selectedWeekday.length === 0} onClick={this.handleSlotSave}> Save Slots </Button>

                                                                        {selectedWeekday ? null : <div>Please Confirm Day of Week for Exam First</div>}

                                                                    </div></div>
                                                            }
                                                        </div>
                                                    }

                                                </div> :

                                                <div>
                                                    <div>A date range was not selected. Please select a date range in order to pick a day of the week.</div>
                                                    <br></br>
                                                    <Button variant="primary" onClick={() => this.setState({ activeKey: "selectDays" })}>
                                                        Select New Day Range
                                                    </Button>
                                                    <br></br>
                                                </div>}
                                        </div> : null}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </div>

                    }




                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="sendSlotEmail" onClick={() => this.setState({ activeKey: "sendSlotEmail" })}>
                            {this.state.selectedLocation.includes("RMV Site") ? "Send Emails/View Status" : "Send Slot Emails/View Status"}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="sendSlotEmail">
                            <Card.Body>
                                {this.state.selectedDays.length > 0 ?

                                    <div>
                                        {this.state.selectedLocation.includes("RMV Site") ?
                                            <div>
                                                {this.state.refreshStatus ? <Alert variant="success">
                                                    <strong>{this.state.refreshStatus}</strong>
                                                </Alert> : null}
                                                {this.state.dataDirty ? <div><Alert variant="warning">
                                                    <strong>Warning: You did not save updates to either the student data above, or the day of the week for the exam. You must save the student data prior to sending any emails.</strong>
                                                    <br></br>
                                                    <br></br>
                                                    <Button variant="primary" onClick={this.handleSlotSave}> Save </Button>
                                                </Alert>

                                                </div> : null}
                                                <Button disabled={this.state.dataDirty || this.state.fetchingStudents || this.state.loading == true} onClick={() => this.sendRoadTestSlotEmailsBulk([])}> Send all Unsent Emails </Button>
                                                <Button disabled={this.state.dataDirty || this.state.fetchingStudents || this.state.loading == true} onClick={this.checkEmailDate}> Check Email Date </Button>
                                                <Button disabled={this.state.dataDirty || this.state.fetchingStudents || this.state.loading == true} onClick={this.copyStudentEmailsToClipboard}> Copy All Student Emails to Clipboard </Button>
                                                <Button disabled={this.state.dataDirty || this.state.fetchingStudents || this.state.loading == true} onClick={this.copyParentEmailsToClipboard}> Copy All Parent Emails to Clipboard </Button>
                                                <Button disabled={this.state.dataDirty || this.state.fetchingStudents || this.state.loading == true} onClick={this.copyAllPhoneNumbersToClipboard}> Copy All Phone Numbers to Clipboard </Button>


                                                {this.state.emailSendDateData.length > 0 ? <div>The emails sent out will have a appointment date of: {this.state.emailSendDateData}</div> : null}

                                                <Button className="rightBut" disabled={this.state.refreshing || this.state.dataDirty} onClick={() => this.refreshStatus(this.state.activeKey)}>  Refresh Status </Button>
                                                <br></br><br></br>
                                                <Table striped bordered hover size="sm">
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Email</th>
                                                            <th>Inital Email</th>
                                                            <th>Time Email</th>
                                                            <th>Confirmation</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    {this.state.unslotedMembers.length > 0 ?
                                                        <tbody>
                                                            {this.state.unslotedMembers.map((x, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{x.fName + " " + x.lName}</td>
                                                                        <td>{x.emailAddress}</td>
                                                                        <td>{x.emailInitalState}</td>
                                                                        <td>{x.emailRoadTestSlotState}</td>
                                                                        <td>{x.emailRoadTestSlotConfirmed}</td>
                                                                        <td>
                                                                            <Button disabled={this.state.dataDirty || this.state.loading} onClick={() => this.sendInitalEmailBulk([x])} size="sm">Send Inital Email</Button>
                                                                            <Button disabled={this.state.dataDirty || this.state.loading || !x.hasOwnProperty("unslotTime")} onClick={() => this.sendRoadTestTimeEmailsBulk([x])} size="sm">Send {x.hasOwnProperty("unslotTime") ? x.unslotTime : "MISSING TIME"} Time Email</Button>
                                                                        </td>


                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody> :
                                                        <div></div>
                                                    }

                                                </Table>
                                            </div> :
                                            <div>
                                                {this.state.slots.length === 0 ?
                                                    <div>
                                                        <div>No slotted students found. Please select a new day or slot some students.</div>
                                                        <div>If you slotted students above, you have to hit the save button.</div>
                                                        <br></br>
                                                        <Button variant="primary" onClick={() => this.setState({ activeKey: "selectDays" })}>
                                                            Select New Day Range </Button>
                                                        <Button variant="primary" onClick={() => this.setState({ activeKey: "slotStudents" })}>
                                                            Slot Some Students </Button>
                                                        <br></br>
                                                    </div> :
                                                    <div>
                                                        {this.state.refreshStatus ? <Alert variant="success">
                                                            <strong>{this.state.refreshStatus}</strong>
                                                        </Alert> : null}
                                                        {this.state.dataDirty ? <div><Alert variant="warning">
                                                            <strong>Warning: You did not save updates to either the student data above, or the day of the week for the exam. You must save the student data prior to sending any emails.</strong>
                                                            <br></br>
                                                            <br></br>
                                                            <Button variant="primary" onClick={this.handleSlotSave}> Save </Button>
                                                        </Alert>

                                                        </div> : null}
                                                        <Button disabled={this.state.dataDirty || this.state.fetchingStudents || this.state.loading == true} onClick={() => this.sendRoadTestSlotEmailsBulk([])}> Send all Unsent Slot Emails </Button>
                                                        <Button disabled={this.state.dataDirty || this.state.fetchingStudents || this.state.loading == true} onClick={this.checkEmailDate}> Check Email Date </Button>
                                                        <Button disabled={this.state.dataDirty || this.state.fetchingStudents || this.state.loading == true} onClick={this.copyStudentEmailsToClipboard}> Copy All Student Emails to Clipboard </Button>
                                                        <Button disabled={this.state.dataDirty || this.state.fetchingStudents || this.state.loading == true} onClick={this.copyParentEmailsToClipboard}> Copy All Parent Emails to Clipboard </Button>
                                                        <Button disabled={this.state.dataDirty || this.state.fetchingStudents || this.state.loading == true} onClick={this.copyAllPhoneNumbersToClipboard}> Copy All Phone Numbers to Clipboard </Button>


                                                        {this.state.emailSendDateData.length > 0 ? <div>The emails sent out will have a appointment date of: {this.state.emailSendDateData}</div> : null}

                                                        <Button className="rightBut" disabled={this.state.refreshing || this.state.dataDirty} onClick={() => this.refreshStatus(this.state.activeKey)}>  Refresh Status </Button>
                                                        <br></br><br></br>
                                                        <Table striped bordered hover size="sm">
                                                            <thead>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Email</th>
                                                                    <th>Weekday</th>
                                                                    <th>Time</th>
                                                                    <th>Inital Email</th>
                                                                    <th>Slot Email</th>
                                                                    <th>Confirmation</th>
                                                                    <th>Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.slots.filter(x => (Object.keys(x.value).length > 0 && x.value.constructor === Object)).map((x, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{x.value.fName + " " + x.value.lName}</td>
                                                                            <td>{x.value.emailAddress}</td>
                                                                            <td>{x.value.weekendDate}</td>
                                                                            <td>{x.value.slot}</td>
                                                                            <td>{x.value.emailInitalState}</td>
                                                                            <td>{x.value.emailRoadTestSlotState}</td>
                                                                            <td>{x.value.emailRoadTestSlotConfirmed}</td>
                                                                            <td>
                                                                                <Button disabled={this.state.dataDirty || this.state.loading} onClick={() => this.sendInitalEmailBulk([x.value])} size="sm">Send Inital Email</Button>
                                                                                <Button disabled={this.state.dataDirty || this.state.loading} onClick={() => this.sendRoadTestSlotEmailsBulk([x.value])} size="sm">Send Slot Email</Button>
                                                                            </td>


                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </Table>
                                                    </div>}
                                            </div>}


                                    </div>

                                    : <div>
                                        <div>A date range was not selected. Please select a date range in order to pick a day of the week.</div>
                                        <br></br>
                                        <Button variant="primary" onClick={() => this.setState({ activeKey: "selectDays" })}>Select New Day Range</Button>
                                        <br></br>
                                    </div>}
                            </Card.Body>

                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="viewCertStatus" onClick={() => this.setState({ activeKey: "viewCertStatus" })}>
                            View Certificate Status
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="viewCertStatus">
                            <Card.Body>
                                {activeKey === "viewCertStatus" ? <div>
                                    {this.state.EmailError ? <Alert variant="warning">
                                        <strong>{this.state.EmailError}</strong>
                                    </Alert> : null}
                                    {this.state.refreshStatus ? <Alert variant="success">
                                        <strong>{this.state.refreshStatus}</strong>
                                    </Alert> : null}
                                </div> : null}


                                {this.state.selectedDays.length > 0 ?
                                    <div>
                                        {this.state.selectedLocation.includes("RMV Site") ? <div>
                                            {this.state.unslotedMembers.length === 0 ?
                                                <div>
                                                    <div>No students found. Please select a new day or add some students.</div>
                                                    <br></br>
                                                    <Button variant="primary" onClick={() => this.setState({ activeKey: "selectDays" })}>
                                                        Select New Day Range
                                                    </Button>
                                                    <br></br>
                                                </div>
                                                :
                                                <div>
                                                    <Button className="rightBut" disabled={this.state.refreshing || this.state.dataDirty} onClick={() => this.refreshStatus(this.state.activeKey)}>  Refresh Status </Button>
                                                    <br></br><br></br>
                                                    {!this.state.dataDirty ?
                                                        <Table striped bordered hover size="sm">
                                                            <thead>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>School</th>
                                                                    <th>Certificate Paid</th>
                                                                    <th>Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.unslotedMembers.map((x, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{x.fName + " " + x.lName}</td>
                                                                            <td>{x.drivingSchool}</td>
                                                                            {!x.hasOwnProperty("certPaymentStatus") || x.certPaymentStatus === false ?
                                                                                x.drivingSchool.includes("Parkway Driving School") ? <td>Not Paid</td> : <td>N/A</td>
                                                                                :
                                                                                <td>Paid</td>
                                                                            }
                                                                            <td>
                                                                                <Button disabled={x.certPaymentStatus === true} onClick={() => this.handleChangeRoadTestCertificateStatus(index, "certPaid", false)} size="sm">Mark Paid</Button>
                                                                                <Button disabled={!x.hasOwnProperty("certPaymentStatus") || x.certPaymentStatus === false} onClick={() => this.handleChangeRoadTestCertificateStatus(index, "certNotPaid", false)} size="sm">Mark Not Paid</Button>

                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </Table> : null}
                                                </div>}
                                        </div> :
                                            <div>
                                                {this.state.slots.length === 0 ?
                                                    <div>
                                                        <div>No slotted students found. Please select a new day or slot some students.</div>
                                                        <div>If you slotted students above, you have to hit the save button.</div>
                                                        <br></br>
                                                        <Button variant="primary" onClick={() => this.setState({ activeKey: "selectDays" })}>
                                                            Select New Day Range
                                                        </Button>
                                                        <Button variant="primary" onClick={() => this.setState({ activeKey: "slotStudents" })}>
                                                            Slot Some Students
                                                        </Button>
                                                        <br></br>
                                                    </div>
                                                    :
                                                    <div>
                                                        <Button className="rightBut" disabled={this.state.refreshing || this.state.dataDirty} onClick={() => this.refreshStatus(this.state.activeKey)}>  Refresh Status </Button>
                                                        <br></br><br></br>
                                                        {!this.state.dataDirty ?
                                                            <Table striped bordered hover size="sm">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Name</th>
                                                                        <th>School</th>
                                                                        <th>Certificate Paid</th>
                                                                        <th>Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.slots.filter(x => (Object.keys(x.value).length > 0 && x.value.constructor === Object)).map((x, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{x.value.fName + " " + x.value.lName}</td>
                                                                                <td>{x.value.drivingSchool}</td>
                                                                                {!x.value.hasOwnProperty("certPaymentStatus") || x.value.certPaymentStatus === false ?
                                                                                    x.value.drivingSchool.includes("Parkway Driving School") ? <td>Not Paid</td> : <td>N/A</td>
                                                                                    :
                                                                                    <td>Paid</td>
                                                                                }
                                                                                <td>
                                                                                    <Button disabled={x.value.certPaymentStatus === true} onClick={() => this.handleChangeRoadTestCertificateStatus(index, "certPaid", true)} size="sm">Mark Paid</Button>
                                                                                    <Button disabled={!x.value.hasOwnProperty("certPaymentStatus") || x.value.certPaymentStatus === false} onClick={() => this.handleChangeRoadTestCertificateStatus(index, "certNotPaid", true)} size="sm">Mark Not Paid</Button>

                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                </tbody>
                                                            </Table> : null}
                                                    </div>}</div>}
                                    </div>

                                    : <div>
                                        <div>A date range was not selected. Please select a date range in order to pick a day of the week.</div>
                                        <br></br>
                                        <Button variant="primary" onClick={() => this.setState({ activeKey: "selectDays" })}>Select New Day Range</Button>
                                        <br></br>
                                    </div>}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="setRoadTestResultEmail" onClick={() => this.setState({ activeKey: "setRoadTestResultEmail" })}>
                            Set Road Test Results/Send Road Test Passed Email
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="setRoadTestResultEmail">
                            <Card.Body>
                                {activeKey === "setRoadTestResultEmail" ? <div>
                                    {this.state.EmailError ? <Alert variant="warning">
                                        <strong>{this.state.EmailError}</strong>
                                    </Alert> : null}
                                    {this.state.refreshStatus ? <Alert variant="success">
                                        <strong>{this.state.refreshStatus}</strong>
                                    </Alert> : null}
                                </div> : null}

                                {this.state.selectedDays.length > 0 ?
                                    <div>
                                        {this.state.selectedLocation.includes("RMV Site") ? <div>
                                            {this.state.unslotedMembers.length === 0 ?
                                                <div>
                                                    <div>No students found. Please select a new day.</div>
                                                    <br></br>
                                                    <Button variant="primary" onClick={() => this.setState({ activeKey: "selectDays" })}>Select New Day Range</Button>
                                                    <br></br>
                                                </div>
                                                :
                                                <div>
                                                    <Button disabled={this.state.dataDirty || this.state.loading} onClick={() => this.handleChangeRoadTestStatus(-100, "markpassed", "all", false)}> Mark all students as passed </Button>
                                                    <Button disabled={this.state.dataDirty || this.state.loading} onClick={() => this.sendRoadTestPassedEmailsBulk(false)}> Send all unsent emails to passed students </Button>
                                                    <Button disabled={this.state.dataDirty || this.state.loading} onClick={() => this.sendRoadTestFailedEmailsBulk(false)}> Send all unsent emails to failed students </Button>
                                                    <Button className="rightBut" disabled={this.state.refreshing || this.state.dataDirty} onClick={() => this.refreshStatus(this.state.activeKey)}>  Refresh Status </Button>
                                                    <br></br><br></br>
                                                    {!this.state.dataDirty ?
                                                        <Table striped bordered hover size="sm">
                                                            <thead>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Road Test Result</th>
                                                                    <th>Email Sent</th>
                                                                    <th>Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.unslotedMembers.map((x, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{x.fName + " " + x.lName}</td>
                                                                            <td>{x.roadTestPassed ?
                                                                                <span>


                                                                                    {x.roadTestPassed === "noshow" ? "No Show" :
                                                                                        x.roadTestPassed === false ? "Not Passed" :
                                                                                            "Passed"}
                                                                                </span>
                                                                                :
                                                                                "Not Passed"
                                                                            }
                                                                            </td>
                                                                            <td>{x.roadTestLicenseEmailSent ?
                                                                                <span>
                                                                                    {x.roadTestLicenseEmailSent === false ? "Not Sent" : "Sent"}
                                                                                </span>
                                                                                :
                                                                                "Not Sent"
                                                                            }
                                                                            </td>
                                                                            <td>
                                                                                <Button disabled={x.roadTestPassed === true} onClick={() => this.handleChangeRoadTestStatus(index, "markpassed", 1, false)} size="sm">Mark Passed</Button>
                                                                                <Button variant="danger" disabled={!x.hasOwnProperty("roadTestPassed") || x.roadTestPassed === false} onClick={() => this.handleChangeRoadTestStatus(index, "marknotpassed", 1, false)} size="sm">Mark Not Passed</Button>
                                                                                <Button variant="warning" disabled={x.roadTestPassed == "noshow"} onClick={() => this.handleChangeRoadTestStatus(index, "marknoshow", 1, false)} size="sm">Mark No Show</Button>
                                                                                <Button variant="info" disabled={!x.hasOwnProperty("roadTestLicenseEmailSent") || x.roadTestLicenseEmailSent === false} onClick={() => this.handleChangeRoadTestStatus(index, "resetRoadTestLicenseEmailSent", 1, false)} size="sm">Reset Sent Status</Button>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </Table> : null}


                                                </div>
                                            }
                                        </div> :
                                            <div>
                                                {this.state.slots.length === 0 ?
                                                    <div>
                                                        <div>No slotted students found. Please select a new day or slot some students.</div>
                                                        <div>If you slotted students above, you have to hit the save button.</div>
                                                        <br></br>
                                                        <Button variant="primary" onClick={() => this.setState({ activeKey: "selectDays" })}>Select New Day Range</Button>
                                                        <Button variant="primary" onClick={() => this.setState({ activeKey: "slotStudents" })}>Slot Some Students</Button>
                                                        <br></br>
                                                    </div>
                                                    :
                                                    <div>
                                                        <Button disabled={this.state.dataDirty || this.state.loading} onClick={() => this.handleChangeRoadTestStatus(-100, "markpassed", "all", true)}> Mark all students as passed </Button>
                                                        <Button disabled={this.state.dataDirty || this.state.loading} onClick={() => this.sendRoadTestPassedEmailsBulk(true)}> Send all unsent emails to passed students </Button>
                                                        <Button disabled={this.state.dataDirty || this.state.loading} onClick={() => this.sendRoadTestFailedEmailsBulk(true)}> Send all unsent emails to failed students </Button>
                                                        <Button className="rightBut" disabled={this.state.refreshing || this.state.dataDirty} onClick={() => this.refreshStatus(this.state.activeKey)}>  Refresh Status </Button>
                                                        <br></br><br></br>
                                                        {!this.state.dataDirty ?
                                                            <Table striped bordered hover size="sm">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Name</th>
                                                                        <th>Road Test Result</th>
                                                                        <th>Email Sent</th>
                                                                        <th>Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.slots.filter(x => (Object.keys(x.value).length > 0 && x.value.constructor === Object)).map((x, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{x.value.fName + " " + x.value.lName}</td>
                                                                                <td>{x.value.roadTestPassed ?
                                                                                    <span>


                                                                                        {x.value.roadTestPassed === "noshow" ? "No Show" :
                                                                                            x.value.roadTestPassed === false ? "Not Passed" :
                                                                                                "Passed"}
                                                                                    </span>
                                                                                    :
                                                                                    "Not Passed"
                                                                                }
                                                                                </td>
                                                                                <td>{x.value.roadTestLicenseEmailSent ?
                                                                                    <span>
                                                                                        {x.value.roadTestLicenseEmailSent === false ? "Not Sent" : "Sent"}
                                                                                    </span>
                                                                                    :
                                                                                    "Not Sent"
                                                                                }
                                                                                </td>
                                                                                <td>
                                                                                    <Button disabled={x.value.roadTestPassed === true} onClick={() => this.handleChangeRoadTestStatus(index, "markpassed", 1, true)} size="sm">Mark Passed</Button>
                                                                                    <Button variant="danger" disabled={!x.value.hasOwnProperty("roadTestPassed") || x.value.roadTestPassed === false} onClick={() => this.handleChangeRoadTestStatus(index, "marknotpassed", 1, true)} size="sm">Mark Not Passed</Button>
                                                                                    <Button variant="warning" disabled={x.value.roadTestPassed == "noshow"} onClick={() => this.handleChangeRoadTestStatus(index, "marknoshow", 1, true)} size="sm">Mark No Show</Button>
                                                                                    <Button variant="info" disabled={!x.value.hasOwnProperty("roadTestLicenseEmailSent") || x.value.roadTestLicenseEmailSent === false} onClick={() => this.handleChangeRoadTestStatus(index, "resetRoadTestLicenseEmailSent", 1, true)} size="sm">Reset Sent Status</Button>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                </tbody>
                                                            </Table> : null}


                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>

                                    : <div>
                                        <div>A date range was not selected. Please select a date range in order to pick a day of the week.</div>
                                        <br></br>
                                        <Button variant="primary" onClick={() => this.setState({ activeKey: "selectDays" })}>Select New Day Range</Button>
                                        <br></br>
                                    </div>}


                            </Card.Body>

                        </Accordion.Collapse>
                    </Card>
                </Accordion>


                <Modal
                    size="lg"
                    show={this.state.showModal}
                    onHide={() => this.setState({ showModal: false, modalView: "" })}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            {this.state.modalView === "add" ? "Add New Student" : null}
                            {this.state.modalView === "edit" ? "Edit Student" : null}
                            {this.state.modalView === "copy" ? "Copy Student" : null}
                            {this.state.modalView === "move" ? "Move Student" : null}
                            {this.state.modalView === "delete" ? "Delete Student" : null}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {this.state.modalView === "add" ?
                            <Form noValidate validated={addModalValidated} onSubmit={this.handleAddNewUser} >
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        name="drivingSchool"
                                        type="text"
                                        value={this.state.drivingSchool}
                                        placeholder="Enter Driving School Name (Optional)"
                                        onChange={this.handleModalInputChange}
                                    />
                                    <InputGroup.Append>
                                        <Button variant="outline-secondary" onClick={() => this.setState({ drivingSchool: "Parkway Driving School" })}>Parkway</Button>
                                    </InputGroup.Append>
                                </InputGroup>

                                <Row>
                                    <Col>
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control required
                                            name="firstName"
                                            type="text"
                                            value={this.state.firstName}
                                            placeholder="First Name"
                                            onChange={this.handleModalInputChange}
                                        />
                                        <Form.Control.Feedback type="invalid">Please input a name.</Form.Control.Feedback>
                                    </Col>
                                    <Col>
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control required
                                            name="lastName"
                                            type="text"
                                            value={this.state.lastName}
                                            placeholder="Last Name"
                                            onChange={this.handleModalInputChange}
                                        />
                                        <Form.Control.Feedback type="invalid">Please input a name.</Form.Control.Feedback>
                                    </Col>
                                </Row>
                                <Form.Group controlId="dateOfBirth">
                                    <Form.Label>Date of Birth</Form.Label>
                                    <Form.Control required
                                        name="dateOfBirth"
                                        type="date"
                                        value={this.state.dateOfBirth}
                                        placeholder="MM/DD/YYYY"
                                        onChange={this.handleModalInputChange}
                                    />
                                    <Form.Control.Feedback type="invalid">Please input a DOB.</Form.Control.Feedback>
                                </Form.Group>

                                <Row>
                                    <Col>
                                        <Form.Group controlId="permitNumber">
                                            <Form.Label>Permit Number</Form.Label>
                                            <Form.Control required
                                                name="permitNumber"
                                                type="text"
                                                value={this.state.permitNumber}
                                                placeholder="Enter text"
                                                onChange={this.handleModalInputChange}
                                            />
                                        </Form.Group>
                                        <Form.Control.Feedback type="invalid">Please input a permit number.</Form.Control.Feedback>

                                    </Col>
                                    <Col>
                                        <Form.Group controlId="permitDate">
                                            <Form.Label>Permit Issue Date</Form.Label>
                                            <Form.Control required
                                                name="permitIssueDate"
                                                type="date"
                                                value={this.state.permitIssueDate}
                                                placeholder="MM/DD/YYYY"
                                                onChange={this.handleModalInputChange}
                                            />
                                            <Form.Control.Feedback type="invalid">Please input a permit issue date.</Form.Control.Feedback>

                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Form.Group controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control required
                                        name="email"
                                        type="email"
                                        value={this.state.email}
                                        placeholder="Enter text"
                                        onChange={this.handleModalInputChange}
                                        autoComplete="email"
                                    />
                                    <Form.Control.Feedback type="invalid">Please input a email address.</Form.Control.Feedback>

                                </Form.Group>
                                <Form.Group controlId="phoneNumber">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control required
                                        name="phoneNumber"
                                        type="tel"
                                        value={this.state.phoneNumber}
                                        placeholder="Enter text"
                                        onChange={this.handleModalInputChange}
                                        autoComplete="tel-national"
                                    />
                                    <Form.Control.Feedback type="invalid">Please input a phone number.</Form.Control.Feedback>

                                </Form.Group>
                                <Form.Group controlId="roadTestPackage">
                                    <Form.Label>Road Test Package</Form.Label>
                                    {this.state.avaliableOptions.map((e, key) => {
                                        return (
                                            <Form.Check
                                                key={key}
                                                required
                                                checked={this.state.roadTestPackageOption === e.package}
                                                name="roadTestPackage"
                                                id={e.package}
                                                type="radio"
                                                label={e.package}
                                                onChange={this.onRadioPackageChange}
                                            />
                                        );
                                    })}
                                    <Form.Control.Feedback type="invalid">Please select a package.</Form.Control.Feedback>

                                </Form.Group>

                                <Form.Check inline label="Add Completion Certificate" type={"checkbox"} id={`addCompletionCertificate`} checked={this.state.addCompletionCertificate} onChange={() => this.setState({ addCompletionCertificate: !this.state.addCompletionCertificate })} />
                                <br></br>
                                <Button type="submit">Add</Button>
                                <br></br>
                                <br></br>



                            </Form>
                            : null}

                        {this.state.modalView === "move" ?
                            <div>
                                <Form.Group controlId="formControlsSelectStudent">
                                    <Form.Label>Select Student</Form.Label>
                                    <Form.Control
                                        name="selectedStudent"
                                        as="select"
                                        onChange={this.handleModalInputChange}
                                    >
                                        {APIDateStudentResults.map((x, index) => {
                                            var name = x.fName + " " + x.lName;
                                            return <option key={name + x.timestamp} value={index}>{name}</option>;
                                        })}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Label>Enter new Date</Form.Label>

                                <AsyncCreatableSelect
                                    value={this.state.newEditMoveDate}
                                    onChange={(data) => this.handleNewMoveDateChoice(data)}
                                    cacheOptions={false}
                                    loadOptions={this.loadRoadTestLessonDates}
                                    defaultOptions
                                />
                                {this.state.moveError.length > 0 ? <div>{this.state.moveError}</div>
                                    : null}

                                <Button disabled={this.state.loading} onClick={this.handleMoveStudent}>
                                    {this.state.loading ? "Please wait. Move Processing" : "Move"}
                                </Button>
                                <div>The student will be moved as soon as the Move button is pressed.</div>
                                <div>All email status and slot data will be cleared.</div>

                            </div> : null
                        }

                        {this.state.modalView === "copy" ?
                            <div>
                                <Form.Group controlId="formControlsSelectStudent">
                                    <Form.Label>Select Student</Form.Label>
                                    <Form.Control
                                        name="selectedStudent"
                                        as="select"
                                        onChange={this.handleModalInputChange}
                                    >
                                        {APIDateStudentResults.map((x, index) => {
                                            var name = x.fName + " " + x.lName;
                                            return <option key={name + x.timestamp} value={index}>{name}</option>;
                                        })}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Label>Enter new Date</Form.Label>
                                <AsyncSelect
                                    value={this.state.newCopyMoveDate}
                                    onChange={(data) => this.handleNewCopyDateChoice(data)}
                                    cacheOptions={false}
                                    loadOptions={this.loadRoadTestLessonDates}
                                    defaultOptions
                                />
                                {this.state.copyError.length > 0 ? <div>{this.state.copyError}</div>
                                    : null}

                                <Button disabled={this.state.loading} onClick={this.handleCopyStudent}>
                                    {this.state.loading ? "Please wait. Copy Processing" : "Copy"}
                                </Button>
                                <div>The student will be copied as soon as the Copy button is pressed.</div>
                            </div> : null
                        }

                        {this.state.modalView === "delete" ?
                            <div>
                                <Form.Group controlId="formControlsSelectStudent">
                                    <Form.Label>Select Student</Form.Label>
                                    <Form.Control
                                        name="selectedStudent"
                                        as="select"
                                        onChange={this.handleModalInputChange}
                                    >
                                        {APIDateStudentResults.map((x, index) => {
                                            var name = x.fName + " " + x.lName;
                                            return <option key={name + x.timestamp} value={index}>{name}</option>;
                                        })}
                                    </Form.Control>
                                </Form.Group>
                                {this.state.deleteError.length > 0 ? <div>{this.state.deleteError}</div>
                                    : null}

                                <Button disabled={this.state.loading} variant="danger" onClick={this.handleDeleteStudent}>
                                    {this.state.loading ? "Please wait. Delete Processing" : "Delete"}
                                </Button>
                                <div>The student will be deleted as soon as the Delete button is pressed.</div>
                            </div> : null
                        }

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({ showModal: false, modalView: "" })}
                        >Close</Button>
                    </Modal.Footer>
                </Modal>


                <Modal
                    size="lg"
                    show={this.state.showManageLessons}
                    onHide={() => this.setState({ showManageLessons: false, modalView: "" })}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="manage-road-test-lessons">
                            Manage Road Test Lessons
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.selectedLessonStudent && Object.keys(this.state.selectedLessonStudent).length > 0 ? this.renderLessonState() : <div>There are no lessons to render.</div>}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({ showManageLessons: false })}
                        >Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
